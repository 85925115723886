import React, { Component } from 'react';
import {isCordova, updateObject, getPopupResponsiveSettings} from '../../shared/utility';
import mobiscroll from "@mobiscroll/react";
import { logger, nl2br, jsDateToday } from '../../shared/utility';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import { mpoFulfilment } from '../../lib/Fulfilment';
//import QrScanPopup from "../qrscan";
import {mpoScanner} from "../../lib/Scanner";
import queryString from "query-string";
import {isBrowser} from "react-device-detect";

class FulfilmentPopup extends Component {

    constructor(props) {
        super(props);
        //logger(props);
        
        this.state = {
            web_qr_reader: false,
            merchant_id: 0,
            fulfilment_types: [],
            selected_fulfilment_type: {},
            selected_fulfilment_options: mpoFulfilment.getDefaultFulfilmentOptions(null, true),
        }

        //this.qrModal = React.createRef();

    }

    selectFulfilmentType = (selectedFulfilmentTypeObj) => {
        //console.log('selectedFulfilmentTypeObj', selectedFulfilmentTypeObj);
        let selectedFulfilmentTypeCode = null;
        for (let code in selectedFulfilmentTypeObj.fulfilment_types) {
            //logger(code);
            selectedFulfilmentTypeCode = code;
            break;
        }

        //logger(selectedFulfilmentTypeObj);
        //logger(selectedFulfilmentTypeCode);
        //logger(selectedFulfilmentTypeObj.fulfilment_types[selectedFulfilmentTypeCode]);

        const selectedFulfilmentType = selectedFulfilmentTypeObj.fulfilment_types[selectedFulfilmentTypeCode];

        let asap = parseInt(selectedFulfilmentType.asap, 10) === 1 &&
                parseInt(selectedFulfilmentTypeObj.availability.available_today, 10) === 1;

        if (this.state.selected_fulfilment_options.hasOwnProperty('asap')) {
            asap = asap && this.state.selected_fulfilment_options.asap;
        }
        const updatedState = updateObject(this.state,
            {
                selected_fulfilment_type: selectedFulfilmentTypeObj,
                selected_fulfilment_options: {
                    ...this.state.selected_fulfilment_options,
                    code: selectedFulfilmentTypeCode,
                    asap: asap,
                    datetime: asap || selectedFulfilmentType.time_frame !== mpoFulfilment.fulfilmentTimeFrameDateTime ? null : this.state.selected_fulfilment_options.datetime,
                    datetimeVal: asap || selectedFulfilmentType.time_frame !== mpoFulfilment.fulfilmentTimeFrameDateTime ? null : this.state.selected_fulfilment_options.datetimeVal,
                    deliverySlotId: selectedFulfilmentType.time_type !== mpoFulfilment.fulfilmentTimeTypeDeliverySlots ? 0 : this.state.selected_fulfilment_options.deliverySlotId,
                    deliverySlotDesc: selectedFulfilmentType.time_type !== mpoFulfilment.fulfilmentTimeTypeDeliverySlots ? "" : this.state.selected_fulfilment_options.deliverySlotDesc,
                    deliveryStopId: selectedFulfilmentType.time_type !== mpoFulfilment.fulfilmentTimeTypeDeliveryStops ? 0 : this.state.selected_fulfilment_options.deliveryStopId,
                    deliveryStopDesc: selectedFulfilmentType.time_type !== mpoFulfilment.fulfilmentTimeTypeDeliveryStops ? "" : this.state.selected_fulfilment_options.deliveryStopDesc,
                    pickupLocationId: selectedFulfilmentType.time_type !== mpoFulfilment.fulfilmentTimeTypePickupLocations ? 0 : this.state.selected_fulfilment_options.pickupLocationId,
                    pickupLocationDesc: selectedFulfilmentType.time_type !== mpoFulfilment.fulfilmentTimeTypePickupLocations ? 0 : this.state.selected_fulfilment_options.pickupLocationDesc,
                    dineinTableNum: selectedFulfilmentTypeCode === mpoFulfilment.fulfilmentTypeCodeDinein ? this.state.selected_fulfilment_options.dineinTableNum.trim() : "",
                    dineinBookingName: selectedFulfilmentTypeCode === mpoFulfilment.fulfilmentTypeCodeBooking ? this.state.selected_fulfilment_options.dineinBookingName.trim() : "",
                    dineinBookingRef: selectedFulfilmentTypeCode === mpoFulfilment.fulfilmentTypeCodeBooking ? this.state.selected_fulfilment_options.dineinBookingRef.trim() : "",
                    dineinBookingPax: selectedFulfilmentTypeCode === mpoFulfilment.fulfilmentTypeCodeBooking ? this.state.selected_fulfilment_options.dineinBookingPax : 2,
                }
            } 
        );
        this.setState(updatedState);
    }

    getNavItemsJsx = () => {
        return this.state.fulfilment_types.map((item) => {
            //console.log('item', item);
            
            let icon = 'truck';
            if (item.hasOwnProperty('icon') && item.icon !== null && item.icon !== '') {
                icon = item.icon;
                //logger('config icon: '+icon);
            } else {
                switch (item.code) {
                    case mpoFulfilment.fulfilmentTypeCodeNone:
                    case mpoFulfilment.fulfilmentTypeCodeUnknown:
                        icon = 'exclamation';
                        break;
                    case mpoFulfilment.fulfilmentTypeCodePickup:
                        icon = 'walking';
                        break;
                    case mpoFulfilment.fulfilmentTypeCodeDelivery:
                        icon = 'motorcycle';
                        break;
                    case mpoFulfilment.fulfilmentTypeCodeDinein:
                        icon = 'utensils';
                        break;
                    case mpoFulfilment.fulfilmentTypeCodeBooking:
                        icon = 'calendar-alt';
                        break;
                    case mpoFulfilment.fulfilmentTypeCodeCustom:
                        if (item.hasOwnProperty('icon') && item.icon !== "") {
                            icon = item.icon;
                        }
                        break;
                    case 'catering':
                        icon = 'shuttle-van';
                        break;
                }
                //logger('default icon: '+icon);
            }
            icon="empty icon fas fa-"+icon;

            return <mobiscroll.NavItem
                        id={item.id} key={item.id} 
                        icon={icon} 
                        isActive={() => this.state.selected_fulfilment_type.id === item.id}
                        selected={this.state.selected_fulfilment_type.id === item.id}
                        replace={true}
                        onClick={this.selectFulfilmentType.bind(null, item)}>
                        {item.verb}
                    </mobiscroll.NavItem>
        });
    }

    checkboxAsapChange = (e) => {
        //console.log(e,this.state);
        const updatedState = updateObject(this.state, 
            {
                selected_fulfilment_options: {
                    ...this.state.selected_fulfilment_options,
                    asap: e.target.checked || this.refs.fulfilmentDateTime === undefined || this.refs.fulfilmentDateTime.instance === undefined,
                    datetime: e.target.checked || this.refs.fulfilmentDateTime === undefined || this.refs.fulfilmentDateTime.instance === undefined ? null : this.refs.fulfilmentDateTime.instance.getVal().getTime(),
                    datetimeVal: e.target.checked || this.refs.fulfilmentDateTime === undefined || this.refs.fulfilmentDateTime.instance === undefined ? null : this.refs.fulfilmentDateTime.instance._value
                }
            } 
        );
        this.setState(updatedState);
    }

    getAsapJsx = (minNotice, units, disabled = false, disabledReason = 'Unavailable today') => {
        // asap check box and est time
        // checked={this.state.checkboxModel[0].value} onChange={(ev) => this.checkboxChange(ev, 0)}
        // onchange set time in datetime box to now + min notice

        let text = null;
        if (disabled && disabledReason !== '') {
            text = disabledReason;
        } else {
            text = "Approx "+minNotice;
            if (units !== 'time') {
                text += " "+units;
            }
        }

        return (
            <mobiscroll.Checkbox ref="fulfilmentAsapCheckbox" checked={this.state.selected_fulfilment_options.asap && !disabled} onChange={this.checkboxAsapChange} disabled={disabled}>
                ASAP
                <span className="mbsc-desc">{text}</span>
            </mobiscroll.Checkbox>
        );
    }

    inputDateTimeSet = (e, inst) => {
        //console.log(e,inst);
        //logger(inst.getVal().getTime());
        //logger(inst.getDate());
        const updatedState = updateObject(this.state, 
            {
                selected_fulfilment_options: {
                    ...this.state.selected_fulfilment_options,
                    asap: false,
                    datetime: inst.getVal().getTime(),
                    datetimeVal: e.valueText
                }
            } 
        );
        this.setState(updatedState);
    }

    getDateTimeJsx = (minNotice, units, stepMins, menuAvailability) => {
        //logger('getDateTimeJsx');
        // date & time, step, availability
        // https://docs.mobiscroll.com/react/datetime#usage
        const step = 5;
        const dowMap = {'w0': 'sun', 'w1': 'mon', 'w2': 'tue', 'w3': 'wed', 'w4': 'thu', 'w5': 'fri', 'w6': 'sat'};

        // https://docs.mobiscroll.com/react/datetime#opt-invalid
        let validRules = [];
        let invalidRules = [];
        let numInvalidDays = 0;
        for (var d in dowMap) {
            //if (dowMap.hasOwnProperty(d)) {
                var dowStr = dowMap[d];
                var isAvailableOnDay = menuAvailability.hasOwnProperty('available_'+dowStr) && parseInt(menuAvailability['available_'+dowStr], 10) === 1;
                if (isAvailableOnDay) {
                    // disable non trading hours for this day
                    var availableFrom = menuAvailability['available_from_'+dowStr] !== null ? menuAvailability['available_from_'+dowStr] : (menuAvailability.hasOwnProperty('available_from') && menuAvailability['available_from'] !== null ? menuAvailability['available_from'] : "00:00");
                    var availableTo = menuAvailability['available_to_'+dowStr] !== null ? menuAvailability['available_to_'+dowStr] : (menuAvailability.hasOwnProperty('available_to') && menuAvailability['available_to'] !== null ? menuAvailability['available_to'] : "00:00");
                    if (availableFrom !== '00:00') {
                        invalidRules.push({d: d, start: '00:00', end: availableFrom}); // todo: minus 1 min
                    }
                    if (availableTo !== '00:00' && availableTo !== '23:59') {
                        invalidRules.push({d: d, start: availableTo, end: '23:59'}); // todo: plus 1 min
                    }
                    // todo: add rule for merchants that have split trading hours
                } else {
                    // disable these days of the week 
                    invalidRules.push(d);
                    numInvalidDays++;
                }
            //}
        } 
        if (numInvalidDays === 7) {
            // no availability has been set up
            mpoSentry.captureMessage('No availability has been set up', Sentry.Severity.Warning);
            invalidRules = [];
        }

        // https://docs.mobiscroll.com/react/datetime#opt-min
        let min_datetime = jsDateToday();
        //logger('FulfilmentTabs.menuAvailability');
        //logger(menuAvailability);
        // todo: test handles when merchant with split trading hours is closed but will be reopening later that day
        if (parseInt(menuAvailability.available_today, 10) === 1) {
            if (menuAvailability.hasOwnProperty('available_from') && menuAvailability['available_from'] !== null && menuAvailability['available_from'] !== "") {
                min_datetime.setHours(menuAvailability.available_from.substr(0,2));
                min_datetime.setMinutes(menuAvailability.available_from.substr(3,2));
            }
        } else if (menuAvailability.online_status === 1) {
            logger('still open now '+minNotice+units+' '+min_datetime.getMinutes());
            min_datetime = new Date();
            if (minNotice > 0) {
                if (units === 'hours') {
                    min_datetime.setHours(min_datetime.getHours() + minNotice);
                } else {
                    // mins
                    min_datetime.setMinutes(min_datetime.getMinutes() + minNotice);
                }
            }
            min_datetime.setMinutes(min_datetime.getMinutes() + stepMins);
            validRules.push({d: min_datetime, start: '00:00', end: '23:59'}); // todo: +30 mins only?
        } else if (numInvalidDays < 7) {
            // tomorrow, or next available day
            min_datetime.setDate(min_datetime.getDate() + 1);
            var dow = min_datetime.toString().substr(0,3).toLowerCase();
            var numChecked = 0;
            while (parseInt(menuAvailability['available_'+dow], 10) === 0 && numChecked < 7) {
                numChecked++;
                min_datetime.setDate(min_datetime.getDate() + 1);
                dow = min_datetime.toString().substr(0,3).toLowerCase();
            }
            //logger(dow);
            if (menuAvailability.hasOwnProperty('available_from_'+dow) && menuAvailability['available_from_'+dow] !== null && menuAvailability['available_from_'+dow] !== "") {
                min_datetime.setHours(menuAvailability['available_from_'+dow].substr(0,2));
                min_datetime.setMinutes(menuAvailability['available_from_'+dow].substr(3,2));
            }
        }
        //logger('min_datetime: '+min_datetime);

        let earliest_date = new Date(min_datetime.getTime());
        earliest_date.setHours(0);
        earliest_date.setMinutes(0);
        //logger(earliest_date);
        // https://docs.mobiscroll.com/react/datetime#opt-valid
        for (let sd in menuAvailability.special_dates) {
            //logger(menuAvailability.special_dates[sd]);
            var isAvailableOnSpecialDay = parseInt(menuAvailability.special_dates[sd].menu_available, 10) === 1;
            var d = menuAvailability.special_dates[sd].ph_date;
            d = new Date(d.substr(0,4), parseInt(d.substr(5,2),10)-1, d.substr(8,2));
            if (isAvailableOnSpecialDay) {
                //validRules.push({d: '2019-10-16', start: '10:00', end: '16:00'});
                //validRules.push(menuAvailability.special_dates[sd].ph_date);
                // ignore if today (already covered)
                //if (d.getTime() !== jsDateToday().getTime()) {
                    //validRules.push({d: d, start: menuAvailability.special_dates[sd].available_from, end: menuAvailability.special_dates[sd].available_to});
                    validRules.push(d);
                    if (d.getTime() < min_datetime.getTime()) {
                        min_datetime = d;
                        min_datetime.setHours(menuAvailability.special_dates[sd].available_from.substr(0,2));
                        min_datetime.setMinutes(menuAvailability.special_dates[sd].available_from.substr(3,2));
                    }
                //}

                var sdAvailableFrom = menuAvailability.special_dates[sd].available_from;
                var sdAvailableTo = menuAvailability.special_dates[sd].available_to;
                if (sdAvailableFrom !== '00:00') {
                    invalidRules.push({d: d, start: '00:00', end: sdAvailableFrom}); // todo: minus 1 min
                }
                if (sdAvailableTo !== '00:00' && sdAvailableTo !== '23:59') {
                    invalidRules.push({d: d, start: sdAvailableTo, end: '23:59'}); // todo: plus 1 min
                }
            } else {
                //logger(d);
                if (d.getTime() === earliest_date.getTime()) {
                    //logger(d);
                    earliest_date.setDate(d.getDate() + 1);
                }
                invalidRules.push(menuAvailability.special_dates[sd].ph_date);
            }
        }
        //logger('earliest_date: '+earliest_date);
        if (earliest_date.getTime() > min_datetime.getTime()) {
            logger('earliest date override');
            min_datetime = earliest_date;
        }
        //logger(min_datetime);

        // https://docs.mobiscroll.com/react/datetime#opt-max
        // max 1 month in future
        // todo: one month for daily, six months for catering
        let datetimeLabel = "Choose a time";
        let max_datetime = jsDateToday();
        max_datetime.setHours(23,59,59,0);
        if (parseInt(menuAvailability.order_for_later,10) === 1) {
            datetimeLabel = "Choose a day/time";
            max_datetime.setMonth(max_datetime.getMonth()+1);
        }
        //logger('max_datetime: '+max_datetime);

        //let selected_datetime = this.state.selected_fulfilment_options.datetime && this.state.selected_fulfilment_options.datetime >= min_datetime && this.state.selected_fulfilment_options.datetime <= max_datetime ? this.state.selected_fulfilment_options.datetime : min_datetime;
        let selected_datetime = new Date(this.state.selected_fulfilment_options.datetime);
        if (selected_datetime < min_datetime || selected_datetime > max_datetime) {
            selected_datetime = min_datetime;
        }
        //console.log('this.state.selected_fulfilment_options.datetime', this.state.selected_fulfilment_options.datetime);
        //console.log('min_datetime', min_datetime);
        //console.log('max_datetime', max_datetime);
        //console.log('selected_datetime', selected_datetime);

        //let disabled = numInvalidDays === 7 || parseInt(menuAvailability.available,10) === 0 || (parseInt(menuAvailability.online_status,10) !== 1 && parseInt(menuAvailability.order_for_later,10) !== 1);
        let disabled = numInvalidDays === 7 || (parseInt(menuAvailability.online_status,10) !== 1 && parseInt(menuAvailability.order_for_later,10) !== 1);
        if (disabled) {
            return null;
        }

        return (
            <label>
                {datetimeLabel}
                <mobiscroll.Datetime
                    ref="fulfilmentDateTime"
                    dateFormat="dd/mm/yy"
                    timeFormat="HH:ii"
                    dateWheels="|D M d|"
                    timeWheels="|HH:ii|"
                    value={selected_datetime}
                    min={min_datetime}
                    max={max_datetime}
                    steps={{minute: step, zeroBased: true}}
                    invalid={invalidRules}
                    valid={validRules}
                    showOnFocus={true}
                    onSet={this.inputDateTimeSet}
                    disabled={disabled}
                />
            </label>
        );
    }

    getCalendarJsx = () => {
        // bookings and catering
        // calendar with open days?
    }

    getDeliveryAddressJsx = () => {
        // select existing or add new
        // option to login?
        // show delivery fee
        return null;
    }

    getPickupLocationsJsx = (locationLabel, locations) => {
        return <p className="mbsc-align-center">Choose OK to proceed to choose a pickup location.</p>;
    }

    getDeliveryLocationsJsx = (locationLabel, locations) => {
        return <p className="mbsc-align-center">Choose OK to proceed to choose a delivery location.</p>;
    }

    getDeliverySlotsJsx = () => {
        return <p className="mbsc-align-center">Choose OK to proceed to enter your delivery address and select an available delivery slot.</p>;
    }

    getCateringJsx = () => {
        // not used
        return null;
    }

    getCustomJsx = () => {
        return <p className="mbsc-align-center">Choose OK to proceed to the menu.</p>;
    }

    renderWebQrJsx = () => {
        return false; //this.state.hasOwnProperty('web_qr_reader') && this.state.web_qr_reader;
    }

    showWebQrReader = (showReader) => {
        if (!this.state.hasOwnProperty('web_qr_reader') || this.state.web_qr_reader !== showReader) {
            const updatedState = updateObject(this.state,
                {
                    web_qr_reader: showReader
                }
            );
            this.setState(updatedState);
        }
    }

    getDineInJsx = (tableLabel, tableDesc) => {

        // Table number
        const tableInput = <mobiscroll.Input labelStyle="stacked" value={this.state.selected_fulfilment_options.dineinTableNum} onChange={this.onInputChange} name="dineinTableNum" data-fieldname="dineinTableNum" placeholder={tableDesc}>{tableLabel}</mobiscroll.Input>

        let qrcode = null;
        if (isCordova() && mpoFulfilment.isDineinQRCodeScannerEnabled(this.state.selected_fulfilment_type, this.state.selected_fulfilment_options)) {
            qrcode = <div className="mbsc-btn-group-block">
                <mobiscroll.Button icon="empty icon fas fa-qrcode" onClick={() => {mpoScanner.ScanQrCode(this.scanTableNumberSuccess);}}>Scan QR Code</mobiscroll.Button>
            </div>
        }
        /*
        if (mpoFulfilment.isDineinQRCodeScannerEnabled(this.state.selected_fulfilment_type, this.state.selected_fulfilment_options)) {
            if (isCordova()) {
                qrcode = <div className="mbsc-btn-group-block">
                    <mobiscroll.Button icon="empty icon fas fa-qrcode" onClick={() => {
                        mpoScanner.ScanQrCode(this.scanTableNumberSuccess);
                    }}>Scan QR Code
                    </mobiscroll.Button>
                </div>
            } else {
                qrcode = <div className="mbsc-btn-group-block">
                    <mobiscroll.Button icon="empty icon fas fa-qrcode" onClick={() => {
                        if (this.renderWebQrJsx()) {
                            this.qrModal.current.refs.popupQrScan.instance.show();
                        } else {
                            this.showWebQrReader(true);
                        }
                    }}>Scan QR Code
                    </mobiscroll.Button>
                </div>
            }
        }
        */

        // logger(this.qrModal.current);
        // this.qrModal.current.refs.popupQrScan.instance.show();

        return <div>{tableInput}{qrcode}</div>

    }

    scanTableNumberSuccess = (result) => {
        if (!result.cancelled && result.format === 'QR_CODE') {

            //mobiscroll.toast({message: result.text, color: 'info'});
            const query = result.text.substring(result.text.lastIndexOf("?"));
            //mobiscroll.toast({message: query, color: 'info'});

            /*
            const params = new URLSearchParams(query);
            const tableNum = params ? params.get('table') : null;
            */
            const params = queryString.parse(query);
            const tableNum = params.table ? params.table : null;
            //mobiscroll.toast({message: tableNum, color: 'info'});

            if (tableNum) {
                const updatedState = updateObject(this.state,
                    {
                        selected_fulfilment_options: {
                            ...this.state.selected_fulfilment_options,
                            dineinTableNum: tableNum
                        }
                    }
                );
                this.setState(updatedState);
            }
        }
    }

    getBookingJsx = (bookingRefLabel, bookingRefDesc, bookingNameLabel, bookingNameDesc, bookingPaxLabel, bookingPaxDesc) => {

        let qrcode = null;
        if (isCordova() && mpoFulfilment.isBookingQRCodeScannerEnabled(this.state.selected_fulfilment_type, this.state.selected_fulfilment_options)) {
            qrcode = <div className="mbsc-btn-group-block">
                <mobiscroll.Button icon="empty icon fas fa-qrcode" onClick={() => {mpoScanner.ScanQrCode(this.scanBookingSuccess);}}>Scan QR Code</mobiscroll.Button>
            </div>
        }

        // booking ref, name, covers
        return <div>
            <mobiscroll.Input labelStyle="stacked" value={this.state.selected_fulfilment_options.dineinBookingRef} onChange={this.onInputChange} name="dineinBookingRef" data-fieldname="dineinBookingRef" placeholder={bookingRefDesc}>{bookingRefLabel}</mobiscroll.Input>
            <mobiscroll.Input labelStyle="stacked" value={this.state.selected_fulfilment_options.dineinBookingName} onChange={this.onInputChange} name="dineinBookingName" data-fieldname="dineinBookingName" placeholder={bookingNameDesc}>{bookingNameLabel}</mobiscroll.Input>
            <mobiscroll.Input labelStyle="stacked" value={this.state.selected_fulfilment_options.dineinBookingPax} onChange={this.onInputChange} name="dineinBookingPax" data-fieldname="dineinBookingPax" placeholder={bookingPaxDesc !== '' ? bookingPaxDesc : '0'}>{bookingPaxLabel}</mobiscroll.Input>
            {qrcode}
        </div>
    }

    scanBookingSuccess = (result) => {
        if (!result.cancelled && result.format === 'QR_CODE') {

            //mobiscroll.toast({message: result.text, color: 'info'});
            const query = result.text.substring(result.text.lastIndexOf("?"));
            //mobiscroll.toast({message: query, color: 'info'});

            const params = queryString.parse(query);
            const bookingName = params.bookingname ? params.bookingname : null;
            const bookingRef = params.bookingref ? params.bookingref : null;
            const bookingPax = params.bookingpax ? params.bookingpax : null;
            //mobiscroll.toast({message: bookingRef, color: 'info'});

            if (bookingRef) {
                const updatedState = updateObject(this.state,
                    {
                        selected_fulfilment_options: {
                            ...this.state.selected_fulfilment_options,
                            dineinBookingRef: bookingRef,
                            dineinBookingName: bookingName,
                            dineinBookingPax: bookingPax
                        }
                    }
                );
                this.setState(updatedState);
            }
        }
    }

    onInputChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        const updatedState = updateObject(this.state, 
            {
                selected_fulfilment_options: {
                    ...this.state.selected_fulfilment_options,
                    [fieldName]: fieldName === "dineinTableNum" || fieldName === "dineinBookingRef" ? e.target.value.toUpperCase() : e.target.value
                }
            } 
        );
        this.setState(updatedState);
    }

    isRequiredFieldsValid = () => {
        if (this.state.selected_fulfilment_options.code === null) {
            mobiscroll.toast({message: "Choose a fulfilment type", duration: 2500, display: 'center', color: 'danger'});
            return false;
        } else if (this.state.selected_fulfilment_options.code === mpoFulfilment.fulfilmentTypeDinein) {
            if (!this.state.selected_fulfilment_options.hasOwnProperty('dineinTableNum') ||
                this.state.selected_fulfilment_options.dineinTableNum === null ||
                this.state.selected_fulfilment_options.dineinTableNum.trim() === "") {
                    mobiscroll.toast({message: "Table is required", duration: 2000, display: 'center', color: 'danger'});
                    return false;                    
                }
        } else if (this.state.selected_fulfilment_options.code === mpoFulfilment.fulfilmentTypeBooking) {
            const selectedFulfilmentTypeSettings = this.state.selected_fulfilment_type.fulfilment_types[mpoFulfilment.fulfilmentTypeBooking];
            if (parseInt(selectedFulfilmentTypeSettings.booking_ref_required,10) === 1 &&
                (!this.state.selected_fulfilment_options.hasOwnProperty('dineinBookingRef') ||
                 this.state.selected_fulfilment_options.dineinBookingRef === null ||
                 this.state.selected_fulfilment_options.dineinBookingRef.trim() === "")) {
                    mobiscroll.toast({message: selectedFulfilmentTypeSettings.booking_ref_label+" is required", duration: 2000, display: 'center', color: 'danger'});
                    return false;                    
                }
            if (parseInt(selectedFulfilmentTypeSettings.booking_name_required,10) === 1 &&
                (!this.state.selected_fulfilment_options.hasOwnProperty('dineinBookingName') ||
                    this.state.selected_fulfilment_options.dineinBookingName === null ||
                    this.state.selected_fulfilment_options.dineinBookingName.trim() === "")) {
                mobiscroll.toast({message: selectedFulfilmentTypeSettings.booking_name_label+" is required", duration: 2000, display: 'center', color: 'danger'});
                return false;
            }
            if (parseInt(selectedFulfilmentTypeSettings.booking_pax_required,10) === 1 &&
                (!this.state.selected_fulfilment_options.hasOwnProperty('dineinBookingPax') ||
                    this.state.selected_fulfilment_options.dineinBookingPax === null ||
                    this.state.selected_fulfilment_options.dineinBookingPax === "")) {
                mobiscroll.toast({message: selectedFulfilmentTypeSettings.booking_pax_label+" is required", duration: 2000, display: 'center', color: 'danger'});
                return false;
            }
        }
        return true
    }

    onBeforeClose = (event, inst) => {
        // https://docs.mobiscroll.com/react/popup#event-onBeforeClose
        // check for OK click, if so the validate and set state
        //console.log(event, inst);
        if (event.button === 'set') {
            // validate and return false to prevent close
            if (!this.isRequiredFieldsValid()) {
                return false;
            }

            //console.log(this.state);
            if (!this.state.selected_fulfilment_options.asap) {
                this.updateStateWithSelectedFulfilmentDateTime();
            }
            this.props.updateStateWithFulfilmentOptions(this.state.selected_fulfilment_options);
            this.props.updateStateWithFulfilmentType(this.state.selected_fulfilment_type);
            //this.props.updateStateWithFulfilmentTypeAndOptions(this.state.selected_fulfilment_type, this.state.selected_fulfilment_options);
            /*
            if (this.props.updateStateWithFulfilmentTypeAndOptions !== undefined && typeof(this.props.updateStateWithFulfilmentTypeAndOptions) === 'function') {
                logger('updateStateWithFulfilmentTypeAndOptions');
                this.props.updateStateWithFulfilmentTypeAndOptions(this.state.selected_fulfilment_type, this.state.selected_fulfilment_options);
                // setTimeout(() => {
                //     this.props.updateStateWithFulfilmentTypeAndOptions(this.state.selected_fulfilment_type, this.state.selected_fulfilment_options);
                // }, 1000);
            } else {
                if (this.props.updateStateWithFulfilmentOptions !== undefined && typeof(this.props.updateStateWithFulfilmentOptions) === 'function') {
                    logger('updateStateWithFulfilmentOptions');
                    this.props.updateStateWithFulfilmentOptions(this.state.selected_fulfilment_options);
                }
                if (this.props.updateStateWithFulfilmentType !== undefined && typeof(this.props.updateStateWithFulfilmentType) === 'function') {
                    logger('updateStateWithFulfilmentType');
                    this.props.updateStateWithFulfilmentType(this.state.selected_fulfilment_type);
                }
                // setTimeout(() => {
                //     this.props.updateStateWithFulfilmentType(this.state.selected_fulfilment_type);
                // }, 500);
            }
             */
        }
        else if (!this.state.selected_fulfilment_options.asap) {
            this.updateStateWithSelectedFulfilmentDateTime();
            this.props.updateStateWithFulfilmentOptions(this.state.selected_fulfilment_options);
        }
    }

    onClose = (event, inst) => {
        // if (this.props.updateStateWithFulfilmentTypeAndOptions !== undefined && typeof(this.props.updateStateWithFulfilmentTypeAndOptions) === 'function') {
        //     this.props.updateStateWithFulfilmentTypeAndOptions(this.state.selected_fulfilment_type, this.state.selected_fulfilment_options);
        // }
        if (this.props.onClose !== undefined && typeof(this.props.onClose) === 'function') {
            this.props.onClose();
        }
    }

    updateStateWithSelectedFulfilmentDateTime = () => {
        if (this.refs.fulfilmentDateTime !== undefined && this.refs.fulfilmentDateTime.instance !== undefined) {
            //logger('FulfilmentTabs.updateStateWithSelectedFulfilmentDateTime not null');
            const updatedState = updateObject(this.state, 
                {
                    selected_fulfilment_options: {
                        ...this.state.selected_fulfilment_options,
                        asap: false,
                        datetime: this.refs.fulfilmentDateTime.instance.getVal().getTime(),
                        datetimeVal: this.refs.fulfilmentDateTime.instance._value
                    }
                } 
            );
            this.setState(updatedState);
        } else if (this.refs.fulfilmentAsapCheckbox && this.refs.fulfilmentAsapCheckbox.instance !== undefined) {
            // force asap if it's the only option available
            //logger('FulfilmentTabs.updateStateWithSelectedFulfilmentDateTime null');
            const updatedState = updateObject(this.state, 
                {
                    selected_fulfilment_options: {
                        ...this.state.selected_fulfilment_options,
                        //asap: true,
                        datetime: null,
                        datetimeVal: null
                    }
                } 
            );
            this.setState(updatedState);
        }
    }

    render = () => {

        //console.log('FulfilmentTabs render', this.state.selected_fulfilment_type, this.state.selected_fulfilment_options);

        let navItems = this.getNavItemsJsx(); 

        let asapContent;
        let asapMinNotice;
        let asapMinNoticeUnits;
        let dateTimeContent;
        let deliverySlotsContent;
        let deliveryAddressContent;
        let tabContent;

        let selectedFulfilmentType = this.state.selected_fulfilment_type;
        const selectedFulfilmentTypeDesc = selectedFulfilmentType.hasOwnProperty('desc') ? selectedFulfilmentType.desc : "";
        const menuAvailability = selectedFulfilmentType.hasOwnProperty('availability') ? selectedFulfilmentType.availability : {};
        //logger(this.state.fulfilment_types);
        //logger(selectedFulfilmentType.code);
        //logger(menuAvailability);
        //logger(this.state.fulfilment_types);
        //logger(selectedFulfilmentType);

        if (selectedFulfilmentType.hasOwnProperty('code')) {
            switch (selectedFulfilmentType.code) {
                case mpoFulfilment.fulfilmentTypeCodePickup:
                    selectedFulfilmentType = selectedFulfilmentType.fulfilment_types[mpoFulfilment.fulfilmentTypePickup];
                    //console.log('selectedFulfilmentType', selectedFulfilmentType);

                    //asapMinNotice = menuAvailability.online_status === 1 ? selectedFulfilmentType.min_notice : menuAvailability.available_from;
                    asapMinNotice = menuAvailability.available_from;
                    //asapMinNoticeUnits = menuAvailability.online_status === 1 ? 'mins' : 'time';
                    asapMinNoticeUnits = 'time';
                    asapContent = parseInt(selectedFulfilmentType.asap, 10) === 1 ? this.getAsapJsx(asapMinNotice, asapMinNoticeUnits, parseInt(menuAvailability.available_today, 10) !== 1 && parseInt(menuAvailability.online_status, 10) !== 1) : null;
                    //asapContent = parseInt(selectedFulfilmentType.asap, 10) === 1 ? this.getAsapJsx(asapMinNotice, asapMinNoticeUnits, parseInt(menuAvailability.available_today, 10) !== 1) : null;
                    if (parseInt(menuAvailability.available_today, 10) !== 1 && parseInt(menuAvailability.online_status, 10) !== 1) {
                        if (selectedFulfilmentType.hasOwnProperty('display_store_closed_text') && parseInt(selectedFulfilmentType.display_store_closed_text, 10) === 1) {
                            asapContent = <mobiscroll.Note color="info"><b>Online store currently closed.</b><br/><span className="empty icon fas fa-arrow-down"></span> Choose a day & time to order for later:</mobiscroll.Note>
                        } else {
                            asapContent = null;
                        }
                    }

                    switch (selectedFulfilmentType.time_frame) {
                        case mpoFulfilment.fulfilmentTimeFrameDateTime:
                            dateTimeContent = this.getDateTimeJsx(selectedFulfilmentType.min_notice, 'mins', selectedFulfilmentType.time_block, menuAvailability);
                            break;
                        case mpoFulfilment.fulfilmentTimeFrameLocation:
                            dateTimeContent = this.getPickupLocationsJsx(selectedFulfilmentType.location_label, selectedFulfilmentType.location_options);
                            break;
                        default:
                            dateTimeContent = null;
                    }

                    tabContent = <div>
                        <p className="mbsc-align-center"><b>{selectedFulfilmentTypeDesc}</b></p>
                        <p className="mbsc-align-center mbsc-txt-s"
                           dangerouslySetInnerHTML={{__html: nl2br(menuAvailability.available_text_pickup)}}></p>
                        {asapContent}
                        {dateTimeContent}
                    </div>;

                    break;

                case mpoFulfilment.fulfilmentTypeCodeDelivery:
                    selectedFulfilmentType = selectedFulfilmentType.fulfilment_types[mpoFulfilment.fulfilmentTypeDelivery];
                    //logger(selectedFulfilmentType);

                    //asapMinNotice = menuAvailability.online_status === 1 ? selectedFulfilmentType.min_notice : menuAvailability.available_from;
                    asapMinNotice = menuAvailability.available_from;
                    //asapMinNoticeUnits = menuAvailability.online_status === 1 ? 'mins' : 'time';
                    asapMinNoticeUnits = 'time';
                    asapContent = parseInt(selectedFulfilmentType.asap, 10) === 1 ? this.getAsapJsx(asapMinNotice, asapMinNoticeUnits, parseInt(menuAvailability.available_today, 10) !== 1 && parseInt(menuAvailability.online_status, 10) !== 1) : null;
                    if (parseInt(menuAvailability.available_today, 10) !== 1 && parseInt(menuAvailability.online_status, 10) !== 1) {
                        if (selectedFulfilmentType.hasOwnProperty('display_store_closed_text') && parseInt(selectedFulfilmentType.display_store_closed_text, 10) === 1) {
                            asapContent = <mobiscroll.Note color="info"><b>Online store currently closed.</b><br /><span className="empty icon fas fa-arrow-down"></span> Choose a day & time to order for later:</mobiscroll.Note>
                        } else {
                            asapContent = null;
                        }
                    }

                    switch (selectedFulfilmentType.time_frame) {
                        case mpoFulfilment.fulfilmentTimeFrameDateTime:
                            dateTimeContent = this.getDateTimeJsx(selectedFulfilmentType.min_notice, 'mins', selectedFulfilmentType.time_block, menuAvailability);
                            break;
                        case mpoFulfilment.fulfilmentTimeFrameLocation:
                            dateTimeContent = this.getDeliveryLocationsJsx(selectedFulfilmentType.location_label, selectedFulfilmentType.location_options);
                            break;
                        default:
                            dateTimeContent = null;
                    }

                    switch (selectedFulfilmentType.time_type) {
                        case mpoFulfilment.fulfilmentTimeTypeDeliverySlots:
                            deliverySlotsContent = this.getDeliverySlotsJsx();
                            break;
                        default:
                            deliverySlotsContent = null;
                    }

                    deliveryAddressContent = null; //selectedFulfilmentType.address_required ? this.getDeliveryAddressJsx() : null;

                    tabContent = <div>
                        <p className="mbsc-align-center"><b>{selectedFulfilmentTypeDesc}</b></p>
                        <p className="mbsc-align-center mbsc-txt-s"
                           dangerouslySetInnerHTML={{__html: nl2br(menuAvailability.available_text_deliver)}}></p>
                        {asapContent}
                        {dateTimeContent}
                        {deliverySlotsContent}
                        {deliveryAddressContent}
                    </div>;

                    break;

                case mpoFulfilment.fulfilmentTypeCodeDinein:
                    selectedFulfilmentType = selectedFulfilmentType.fulfilment_types[mpoFulfilment.fulfilmentTypeDinein];
                    const dineinContent = this.getDineInJsx(selectedFulfilmentType.table_num_label, selectedFulfilmentType.table_num_desc);
                    tabContent = <div>
                        <p className="mbsc-align-center"><b>{selectedFulfilmentTypeDesc}</b></p>
                        <p className="mbsc-align-center mbsc-txt-s"
                           dangerouslySetInnerHTML={{__html: nl2br(menuAvailability.available_text_dinein)}}></p>
                        {dineinContent}
                    </div>;
                    break;

                case mpoFulfilment.fulfilmentTypeCodeBooking:
                    selectedFulfilmentType = selectedFulfilmentType.fulfilment_types[mpoFulfilment.fulfilmentTypeBooking];
                    const bookingContent = this.getBookingJsx(
                        selectedFulfilmentType.booking_ref_label, selectedFulfilmentType.booking_ref_desc,
                        selectedFulfilmentType.booking_name_label, selectedFulfilmentType.booking_name_desc,
                        selectedFulfilmentType.booking_pax_label, selectedFulfilmentType.booking_pax_desc
                    );

                    asapMinNotice = menuAvailability.available_from;
                    asapMinNoticeUnits = 'time';
                    asapContent = parseInt(selectedFulfilmentType.asap, 10) === 1 ? this.getAsapJsx(asapMinNotice, asapMinNoticeUnits, parseInt(menuAvailability.available_today, 10) !== 1 && parseInt(menuAvailability.online_status, 10) !== 1) : null;

                    dateTimeContent = this.getDateTimeJsx(selectedFulfilmentType.min_notice, 'mins', selectedFulfilmentType.time_block, menuAvailability, "Booking Date & Time");
                    tabContent = <div>
                        <p className="mbsc-align-center"><b>{selectedFulfilmentTypeDesc}</b></p>
                        {asapContent}
                        {dateTimeContent}
                        {bookingContent}
                    </div>;
                    break;

                case 'catering':
                    tabContent = <div>
                        <p className="mbsc-align-center"><b>{selectedFulfilmentTypeDesc}</b></p>
                    </div>;
                    break;

                case mpoFulfilment.fulfilmentTypeCodeCustom:
                    const customContent = this.getCustomJsx();
                    tabContent = <div>
                        <p className="mbsc-align-center"><b>{selectedFulfilmentTypeDesc}</b></p>
                        {customContent}
                    </div>;
                    break;

                default:
                    tabContent = <mobiscroll.Note color="info">
                        <p className="mbsc-align-center">Choose a fulfilment type above</p>
                    </mobiscroll.Note>;
                    break;
            }
        } else if (this.state.fulfilment_types.length > 0) {
            let fulfilmentTypesText = "";
            for (var i = 0; i < this.state.fulfilment_types.length; i++) {
                //console.log(this.state.fulfilment_types[i]);
                if (i > 0) {
                    if (i === this.state.fulfilment_types.length-1) {
                        fulfilmentTypesText += " or ";
                    } else {
                        fulfilmentTypesText += ", ";
                    }
                }
                fulfilmentTypesText += this.state.fulfilment_types[i].verb
            }
            tabContent = <mobiscroll.Note color="info">
                <p className="mbsc-align-center">
                    <span className="empty icon fas fa-arrow-up"></span> Choose {fulfilmentTypesText} above
                </p>
            </mobiscroll.Note>;
        } else {
            tabContent = <mobiscroll.Note color="danger">
                <p className="mbsc-align-center">No fulfilment types</p>
            </mobiscroll.Note>;
        }

        return (
            <mobiscroll.Popup
                ref="popupFulfilment"
                responsive={getPopupResponsiveSettings(mobiscroll.platform.name === 'ios')}
                layout="liquid"
                headerText={mobiscroll.platform.name !== 'ios' ? "Choose Fulfilment" : null }
                closeOnOverlayTap={false}
                /*
                buttons={[{ 
                    text: 'Proceed',
                    handler: (event, inst) => {
                        alert('Custom button clicked!'); 
                    } 
                },
                'cancel']}
                */
                onBeforeClose={this.onBeforeClose}
                onClose={this.onClose}
            >
                <mobiscroll.TabNav display="inline">
                    {navItems}
                </mobiscroll.TabNav>

                <mobiscroll.Form labelStyle="stacked">
                    {tabContent}
                </mobiscroll.Form>

                {/*
                {this.renderWebQrJsx() ?
                    <QrScanPopup ref={this.qrModal} />
                : null }
                */}

            </mobiscroll.Popup>
        );
    }

}

export default FulfilmentPopup;