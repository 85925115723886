import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import update from 'immutability-helper';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import OrderHistoryItem from '../../components/account/OrderHistoryItem';
import OrderDetailsItem from '../../components/account/OrderDetailsItem';
import StandingOrderItem from '../../components/account/StandingOrderItem';
import AccountBottomNav from '../../components/account/BottomNav';
import {Helmet} from "react-helmet";
import {mpoOneSignal} from "../../lib/OneSignal";
import {isProduction} from "../../shared/utility";

const isCustomApp = !isProduction() || process.env.REACT_APP_CUSTOM_APP === 'true';

class AccountOrders extends Component {

    constructor(props) {
        super(props);
        //console.log('Orders props', props);

        this.state = {
            isLoading: true,
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            mode: '',
            order_id: 0,
            orders: [],
            standing_orders: [],
        }
    }

    componentDidMount = () => {
        //console.log('Orders componentDidMount', this.props);
        mpoSentry.addBreadcrumb('nav','Orders',Sentry.Severity.Info);
        this.getAccount();
    }

    componentDidUpdate(prevProps) {
        //console.log('Orders componentDidUpdate',prevProps, this.props);
        //console.log('slug', orderSlug);
        if (!this.state.isLoading) {
            let orderSlug = this.props.match !== undefined ? this.props.match.params.slug : 0;
            if (orderSlug !== this.state.order_id) {
                if (orderSlug && parseInt(orderSlug, 10) > 0) {
                    this.loadOrder(orderSlug);
                } else if (this.state.mode !== 'list') {
                    this.loadOrderHistory();
                }
            }
        }
    }

    getAccount = () => {

        const data = {
            RequestAction: 'CustomerAccount'
        };

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
            } else if (response.data.ResponseCode === "SUCCESS") {
                if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                    this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                    mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                    //mpoSentry.captureMessage('Session expired', Sentry.Severity.Warning);
                } else {
                    //console.log(this.state);
                    this.setState({
                        //isLoading: false,
                        modules: {
                            standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                            rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                            topup: response.data.Response.account.is_topup_enabled === 1,
                            facebook: response.data.Response.customer.is_facebook_connected,
                            apple: response.data.Response.customer.is_apple_connected,
                            google: response.data.Response.customer.is_google_connected
                        }
                    });

                    let orderSlug = this.props.match !== undefined ? this.props.match.params.slug : 0;
                    if (orderSlug && parseInt(orderSlug, 10) > 0 && orderSlug !== this.state.order_id) {
                        // fetch specific order
                        this.loadOrder(orderSlug);
                    } else if (this.state.mode !== 'list') {
                        // list orders
                        this.loadOrderHistory();
                    } 
                }
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    loadOrderHistory = () => {
        //console.log('loadOrderHistory');

        this.setState({
            isLoading: this.props.user.customer.id > 0,
            mode: 'list',
            order_id: 0,
            orders: [],
            standing_orders: [],
        });

        const data = {
            RequestAction: 'OrderHistory',
        };

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response, this.props);
            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
            } else if (response.data.ResponseCode === "SUCCESS") {
                //this.props.updateStateWithCustomer(response.data.Response.customer, null);
                //console.log(this.state);
                this.setState({
                    isLoading: false,
                    mode: 'list',
                    orders: response.data.Response.orders,
                    standing_orders: response.data.Response.standing_orders
                });
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    loadOrder = (orderId) => {

        this.setState({
            isLoading: this.props.user.customer.id > 0,
            mode: 'receipt',
            order_id: orderId,
            orders: [],
            standing_orders: [],
        });

        const data = {
            RequestAction: 'OrderStatusDetailed',
            order_id: orderId,
        };

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response, this.props);
            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
            } else if (response.data.ResponseCode === "SUCCESS") {
                //this.props.updateStateWithCustomer(response.data.Response.customer, null);
                if (response.data.Response.order.hasOwnProperty('id')) {
                    let order = response.data.Response.order;
                    order.items = response.data.Response.order_items;
                    order.settings = response.data.Response.settings;
                    this.setState({
                        isLoading: false,
                        mode: 'receipt',
                        orders: [order]
                    });
                    const isActive = parseInt(order.nstatus,10) < 5;
                    if (isActive) {
                        setTimeout(mpoOneSignal.TriggerInAppIosPrompt, 3000);
                    }
                } else {
                    mobiscroll.toast({message: 'Order not found', color: 'danger'});
                    this.props.history.push('/account/orders/');
                }
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    onOrderHistoryItemTap = (event, inst) => {
        this.props.history.push('/account/orders/'+this.state.orders[event.index].id);
    }

    onStandingOrderItemTap = (event, inst) => {
        this.props.history.push('/account/standingorder/'+this.state.standing_orders[event.index].id);
    }

    render = () => {
        let isData = this.state.orders.length > 0;
        let headingStandingOrders = 'Standing Orders';
        let headingOrderHistory = 'Order History';
        //console.log('Orders render', this.state, this.props.user.customer, isData);

        if (this.state.isLoading) {
            headingStandingOrders = headingOrderHistory = 'Loading...';
        } else if (this.props.user.customer.id === 0) {
            headingStandingOrders = headingOrderHistory = 'Account required';
        } else if (this.state.mode === 'receipt') {
            headingOrderHistory = 'Order #'+this.state.order_id;
        }

        /*
        if (!isData) {
            return (
                <div className="app-tab">
                    <mobiscroll.Form className="mpo-form-width-md">
                    </mobiscroll.Form>
    
                    <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
                </div>
            );
        }
        */

        return (
            <div className="app-tab">
                <Helmet>
                    <title>{process.env.REACT_APP_APP_TITLE} {this.state.mode === 'receipt' ? "Order #"+this.state.order_id : "Orders" }</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    {this.state.mode === 'receipt' && isData ? 
                    <OrderDetailsItem order={this.state.orders[0]} modules={this.state.modules} />
                    :
                    <div className="mbsc-form-group">
                        {isCustomApp && this.state.modules.standing_orders ?
                            <React.Fragment>
                            <div className="mbsc-form-group-title">{headingStandingOrders}</div>

                                {!this.state.isLoading ?
                                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/standingorder') }}>Create Standing/Recurring Order</mobiscroll.Button>                                </div>
                                : null }

                                <mobiscroll.Listview
                                    className="mbsc-product-list"
                                    itemType={StandingOrderItem}
                                    data={this.state.standing_orders}
                                    fixedHeader={true}
                                    enhance={true}
                                    swipe={false}
                                    striped={true}
                                    onItemTap={this.onStandingOrderItemTap}
                                />
                            </React.Fragment>
                        : null }

                        <div className="mbsc-form-group-title">{headingOrderHistory}</div>

                        {!this.state.isLoading ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/') }}>Create Order</mobiscroll.Button>
                            </div>
                            : null }

                        <mobiscroll.Listview
                            className="mbsc-product-list"
                            itemType={OrderHistoryItem}
                            data={this.state.orders}
                            fixedHeader={true}
                            enhance={true}
                            swipe={false}
                            striped={true}
                            onItemTap={this.onOrderHistoryItemTap}
                        />
                    </div>
                    }
                </mobiscroll.Form>

                {!this.state.isLoading ?
                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
                : null }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account/orders';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountOrders));
