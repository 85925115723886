import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import {updateObject, logger, isGoogleMapsLoaded} from '../../shared/utility';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';

class DeliveryAddress extends Component {

    constructor (props) {
        super(props);
        //logger("Account/DeliveryAddress");
        //logger(props);
        //logger(this.props.merchant);

        const countryCodeOrCodes = this.props.merchant.hasOwnProperty('locale') && this.props.merchant.locale &&
                                    this.props.merchant.locale.hasOwnProperty('country_code') && this.props.merchant.locale.country_code ?
                                    this.props.merchant.locale.country_code : ['AU','NZ'];

        const placesSearchOptions = {
            componentRestrictions: {'country': countryCodeOrCodes},
            types: ['address']
        };

        /*eslint-disable no-undef*/
        const location = isGoogleMapsLoaded() &&
            this.props.merchant.hasOwnProperty('location') && this.props.merchant.location &&
            this.props.merchant.location.hasOwnProperty('lat') && this.props.merchant.location.lat &&
            this.props.merchant.location.lat !== '' ? new google.maps.LatLng(this.props.merchant.location.lat, this.props.merchant.location.long) : null;
        /*eslint-enable no-undef*/

        if (location !== null) {
            placesSearchOptions['location'] = location;
            const radiusKm = this.props.merchant.location.hasOwnProperty('radius') ? parseInt(this.props.merchant.location.radius,10) : 100;
            placesSearchOptions['radius'] = radiusKm * 1000; // delivery addresses within 100km
        }

        this.state = {
            placesSearchOptions: placesSearchOptions,
            delivery: this.props.delivery,
            deliveryAddressManual: (this.props.delivery.delivery_address === "" && this.props.delivery.delivery_postcode !== "") || (this.props.delivery.delivery_address !== "" && this.props.delivery.delivery_postcode === "")
        }

        this.handleChangeDeliveryAddress = this.handleChangeDeliveryAddress.bind(this);
    }

    handleChangeDeliveryAddress = () => {
        // console.log("Account/DeliveryAddress.handleChangeDeliveryAddress");
        // console.log(this.state);
        if (this.props.handleChangeDeliveryAddress !== undefined && typeof(this.props.handleChangeDeliveryAddress) === 'function') {
            this.props.handleChangeDeliveryAddress(this.state);
        }
    }

    componentDidMount = () => {
        const showDeliveryAddressDropdown = this.props.user.auth.isLoggedIn && this.props.user.customer.delivery_addresses.length > 0;
        const showDeliveryAddressLookup = !showDeliveryAddressDropdown || this.state.delivery.delivery_address_id === 0;
        const showDeliveryAddressManualEntry = showDeliveryAddressLookup && (this.state.deliveryAddressManual || (this.state.delivery.delivery_address === "" && this.state.delivery.delivery_postcode !== ""));

        if (showDeliveryAddressDropdown && !showDeliveryAddressManualEntry && this.state.delivery.delivery_address === "") {
            const delivery_address_id = (this.props.hasOwnProperty('delivery_address_id') && parseInt(this.props.delivery_address_id,10) > 0) ? parseInt(this.props.delivery_address_id,10) : parseInt(this.props.user.customer.delivery_addresses[0].id,10);
            this.updateStateWithDeliveryAddressId(delivery_address_id);
        }
    }

    setDeliveryAddressId = (event, inst) => {
        this.updateStateWithDeliveryAddressId(parseInt(inst.getVal(),10));
    }

    updateStateWithDeliveryAddressId = (deliveryAddressId) => {
        this.setState({
            delivery: updateObject(this.state.delivery, {
                delivery_address_id: deliveryAddressId,
                delivery_validated: false,
                delivery_postcode_id: 0,
            })
        }, this.validateDeliveryAddress);
    }

    onDeliveryAddressChange = (address) => {
        this.setState({
            delivery: updateObject(this.state.delivery, {
                delivery_address: address,
                delivery_geo: {},
                delivery_latlng: {},
                delivery_address_id: 0,
                delivery_address1: "",
                delivery_address2: "",
                delivery_city: "",
                delivery_state: "",
                delivery_postcode: "",
                delivery_country: "",
                delivery_validated: false,
                delivery_postcode_id: 0,
            })
        }, this.handleChangeDeliveryAddress);
    }

    onDeliveryAddressManualChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = fieldName === 'delivery_state' ? e.target.value.toUpperCase() : e.target.value;
        //logger('onDeliveryAddressManualChange '+fieldName+'='+fieldValue);
        this.setState({
            delivery: updateObject(this.state.delivery, {
                delivery_address_id: 0,
                delivery_address: "",
                delivery_geo: {},
                delivery_latlng: {},
                delivery_country: "",
                delivery_validated: false,
                delivery_postcode_id: 0,
                [fieldName]: fieldValue
            })
        }, this.handleChangeDeliveryAddress);
    }

    onDeliveryAddressSelect = (address) => {
        //logger(address);
        this.onDeliveryAddressChange(address);
        if (address === null || address === "") {
            return;
        }
        geocodeByAddress(address)
            .then(results => {
                //console.log('geo', results); 
                const addr = {
                    delivery_address_id: 0,
                    delivery_address1: "",
                    delivery_address2: "",
                    delivery_city: "",
                    delivery_state: "",
                    delivery_postcode: "",
                    delivery_country: "",
                    delivery_geo: {},
                    delivery_latlng: {},
                    delivery_validated: false,
                    delivery_postcode_id: 0,
                };
                if (results !== undefined && results[0] !== undefined) {
                    addr.delivery_geo = results[0];
                    let has_street_num = false;
                    let has_postcode = false;
                    if (results[0].address_components !== undefined) {
                        for (let a of results[0].address_components) {
                            for(var i = 0; i < a['types'].length; i++) {
                                switch (a['types'][i]) {
                                    case 'street_number':
                                        has_street_num = true;
                                    case 'route':
                                        if (addr.delivery_address1 !== "") {
                                            addr.delivery_address1 += " ";
                                        }
                                        addr.delivery_address1 += a['short_name'];
                                        break;
                                    case 'locality':
                                        addr.delivery_city = a['short_name'];
                                        break;
                                    case 'administrative_area_level_1':
                                        addr.delivery_state = a['short_name'];
                                        break;
                                    case 'postal_code':
                                        has_postcode = true;
                                        addr.delivery_postcode = a['short_name'];
                                        break;
                                    case 'country':
                                        addr.delivery_country = a['short_name'];
                                        break;
                                }
                            }
                        }
                    }
                    if (!has_street_num) {
                        //logger('NO STREET NUMBER');
                        //logger('daddr1: '+addr.delivery_address1);
                        //logger('daddrStr: '+address);
                        mpoSentry.captureMessage('NO STREET NUMBER', Sentry.Severity.Warning);
                        mobiscroll.toast({message: "Error confirming street address, please enter it manually", duration: 6000, color: 'danger'});
                        //this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        this.updateDeliveryAddressManual(true);
                    } else if (!has_postcode) {
                        //logger('NO POST CODE');
                        //logger('daddr1: '+addr.delivery_address1);
                        //logger('daddrStr: '+address);
                        mpoSentry.captureMessage('NO POST CODE', Sentry.Severity.Warning);
                        mobiscroll.toast({message: "Error confirming address, please enter it manually", duration: 6000, color: 'danger'});
                        //this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        this.updateDeliveryAddressManual(true);
                    } else {
                        //this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        
                        // this.updateCheckout to validate address and get delivery fee
                        //this.updateCheckout({'delivery_address': addr.delivery_address1+','+addr.delivery_address2+','+addr.delivery_city+','+addr.delivery_state+','+addr.delivery_postcode+','+addr.delivery_country});
                        
                        getLatLng(results[0])
                            .then(latLng => {
                                //console.log('Success2', latLng);
                                const updatedState = {
                                    delivery_latlng: latLng
                                }
                                //this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, updatedState));
                            })
                            .catch(error => {
                                //console.error('Error2', error)
                                mpoSentry.captureException(error);
                            });
                    }
                }
            })
            .catch(error => {
                //console.error('Error1', error)
                mpoSentry.captureException(error);
                mobiscroll.toast({message: "Error searching for address. Please enter your delivery address manually.", duration: 6000, color: 'danger'});
                this.updateDeliveryAddressManual(true);
            });
    }

    updateDeliveryAddressManual = (val) => {
        const updatedState = updateObject(this.state, {
            deliveryAddressManual: val
        });
        this.setState(updatedState, this.handleChangeDeliveryAddress);
    }

    validateDeliveryAddress = () => {

        const data = {
            RequestAction: 'DeliveryAddress',
            sub_action: 'validate',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            delivery_address: this.state.delivery,
            component: 'Account/DeliveryAddress'
        };

        //console.log('validateDeliveryAddress', data);
        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    const updatedState = updateObject(this.state, {
                        delivery: response.data.Response,
                    });
                    this.setState(updatedState, this.handleChangeDeliveryAddress);
                    if (response.data.Response.hasOwnProperty('result') && response.data.Response.result.hasOwnProperty('error') && response.data.Response.result.error !== '') {
                        mobiscroll.toast({message: response.data.Response.result.error, color: 'danger'});
                    }
                } else {
                    let errorMsg = response.data.Response[0];
                    mobiscroll.toast({message: errorMsg, color: 'danger'});
                    mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);

                    if (errorMsg.includes('Session expired')) {
                        //this.props.updateStateWithCustomer({id: 0, status: 0});
                    }
                }
            })
            .catch(error => {
                logger(error);
                mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                mpoSentry.captureException(error);
            });

    }

    isValidDeliveryAddress = () => {
        return (this.state.delivery.delivery_address_id > 0 || this.state.delivery.delivery_postcode !== "") && this.state.delivery.delivery_validated && this.state.delivery.delivery_postcode_id > 0;
    }

    getDeliveryText = () => {
        if (this.isValidDeliveryAddress()) {
            return "We deliver to your address";
        } else if (this.state.delivery.hasOwnProperty('result') && this.state.delivery.result.hasOwnProperty('error') && this.state.delivery.result.error !== '') {
            return this.state.delivery.result.error;
        } else {
            return "Enter your delivery address to check we deliver to your area";
        }
    }

    render = () => {

        let deliveryAddressDropdown = null;
        const showDeliveryAddressDropdown = this.props.user.auth.isLoggedIn && this.props.user.customer.delivery_addresses.length > 0;
        const showDeliveryAddressLookup = !showDeliveryAddressDropdown || this.state.delivery.delivery_address_id === 0;
        const showDeliveryAddressManualEntry = showDeliveryAddressLookup && (this.state.deliveryAddressManual || (this.state.delivery.delivery_address === "" && this.state.delivery.delivery_postcode !== ""));

        if (showDeliveryAddressDropdown) {
            deliveryAddressDropdown = <label>
                Delivery address
                <mobiscroll.Select
                    select="single"
                    value={this.state.delivery.delivery_address_id}
                    placeholder="Choose address or enter new one"
                    labelStyle="stacked"
                    onSet={this.setDeliveryAddressId}
                >
                    <option key={0} value={0}>New delivery address</option>
                    {this.props.user.customer.delivery_addresses.map((addr, idx) => <option key={addr.id} value={addr.id}>{addr.address}</option>)}
                </mobiscroll.Select>
            </label>
        }

        const isValidDeliveryAddress = this.isValidDeliveryAddress();
        const deliveryText = this.getDeliveryText();

        return (
            <React.Fragment>

                {!this.props.hasOwnProperty('showHeader') || this.props.showHeader ?
                <div className="mbsc-form-group-title">{this.props.headerText ? this.props.headerText : "Delivery"}</div>
                    : null}

                {!this.props.hasOwnProperty('showCustomerInfo') || this.props.showCustomerInfo ?
                    <React.Fragment>
                        {this.props.user.auth.isLoggedIn ?
                            <div className="mbsc-padding" style={{paddingTop: 0}}><span className="mbsc-txt-s">Logged-in as {this.props.user.customer.name}</span></div>
                            :
                            <React.Fragment>
                                <div className="mbsc-form-group-title">Existing Customers</div>
                                <div className="mbsc-padding" style={{paddingTop: 0}}>
                                    <span className="mbsc-txt-s"><a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push('/menu/login') }}>Sign in</a> to select a previously used delivery address</span>
                                </div>
                                <div className="mbsc-form-group-title">New Customers</div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                    : null}

                {deliveryText ?
                    <React.Fragment>
                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            {isValidDeliveryAddress ? <span className="empty icon fas fa-check-circle" style={{color: "green"}}></span> : <span className="empty icon fas fa-times-circle" style={{color: "red"}}></span> } <span className="mbsc-txt-s">{deliveryText}</span>
                        </div>
                    </React.Fragment>
                    : null}

                {deliveryAddressDropdown}

                {showDeliveryAddressLookup ?
                <React.Fragment>
                    <PlacesAutocomplete
                        value={this.state.delivery.delivery_address}
                        onChange={this.onDeliveryAddressChange}
                        onSelect={this.onDeliveryAddressSelect}
                        onError={this.onDeliveryAddressError}
                        searchOptions={this.state.placesSearchOptions}
                        shouldFetchSuggestions={this.state.delivery.delivery_address.length > 3}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <React.Fragment>
                            <mobiscroll.Input 
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                                labelStyle="stacked" 
                                placeholder="Start typing your address here" 
                                type="text" 
                                name="searchDeliveryAddress">{showDeliveryAddressDropdown ? "New delivery address" : "Delivery address"}</mobiscroll.Input>
                            <div className="autocomplete-dropdown-container">
                            {loading && <div className="suggestion-item">Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active ? 'suggestion-item suggestion-item-active': 'suggestion-item';
                                //logger(suggestion);
                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                                );
                            })}
                            </div>
                        </React.Fragment>
                        )}
                    </PlacesAutocomplete>
                    
                    {showDeliveryAddressManualEntry ?
                    <React.Fragment>
                        <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.state.delivery.delivery_address1} onChange={this.onDeliveryAddressManualChange} name="deliveryAddress1" data-fieldname="delivery_address1" autoComplete={this.props.user.auth.isStaff ? "off" : "address-line1"}>{this.props.merchant.locale.labels.address1}</mobiscroll.Input>
                        {(this.props.merchant.locale.labels.hasOwnProperty('address2') && this.props.merchant.locale.labels.address2 !== '') ?
                        <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.state.delivery.delivery_address2} onChange={this.onDeliveryAddressManualChange} name="deliveryAddress2" data-fieldname="delivery_address2" autoComplete={this.props.user.auth.isStaff ? "off" : "address-line2"}>{this.props.merchant.locale.labels.address2}</mobiscroll.Input>
                        : null}
                        <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.state.delivery.delivery_city} onChange={this.onDeliveryAddressManualChange} name="deliveryCity" data-fieldname="delivery_city" autoComplete={this.props.user.auth.isStaff ? "off" : "address-level2"}>{this.props.merchant.locale.labels.city_suburb}</mobiscroll.Input>
                        {(this.props.merchant.locale.labels.hasOwnProperty('state') && this.props.merchant.locale.labels.state !== '') ?
                        <mobiscroll.Input labelStyle="stacked" style={{textTransform: "upperCase"}} value={this.state.delivery.delivery_state} onChange={this.onDeliveryAddressManualChange} name="deliveryState" data-fieldname="delivery_state" autoComplete={this.props.user.auth.isStaff ? "off" : "address-level1"}>{this.props.merchant.locale.labels.state}</mobiscroll.Input>
                        : null}
                        <mobiscroll.Input labelStyle="stacked" style={{textTransform: "upperCase"}} value={this.state.delivery.delivery_postcode} onChange={this.onDeliveryAddressManualChange} name="deliveryPostcode" data-fieldname="delivery_postcode" autoComplete={this.props.user.auth.isStaff ? "off" : "postal-code"}>{this.props.merchant.locale.labels.postcode}</mobiscroll.Input>
                        {this.props.showValidateButton ?
                        <div className="mbsc-btn-group-block">
                            <mobiscroll.Button color="success" style={{color: '#fff'}} onClick={() => { this.validateDeliveryAddress(); }}>Validate Address</mobiscroll.Button>
                        </div> : null}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="mbsc-padding" style={{paddingTop: "0.5em", paddingBottom: "0"}}><span className="mbsc-txt-s" style={{textTransform: "none"}}><a href="#" onClick={(e) => { e.preventDefault(); this.updateDeliveryAddressManual(true); }}>Enter address manually instead</a></span></div>
                    </React.Fragment>
                    }
                </React.Fragment>
                : null }
            </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer) => dispatch(actions.setCustomerAction(customer, null, null))
    }
}

//export default DeliveryAddress;
//export default withRouter(DeliveryAddress);
export default withRouter( connect(mapStateToProps, mapDispatchToProps)(DeliveryAddress) );
