import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import { mpoAccount } from '../../lib/Account';
import {
    updateObject,
    logger,
    isIframe,
    attachDeviceInfoToData,
    isCordova,
    openWindow
} from '../../shared/utility';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import AccountBottomNav from '../../components/account/BottomNav';
import {Helmet} from "react-helmet";
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import {mpoOneSignal} from "../../lib/OneSignal";
import {mpoCoinbase} from "../../lib/Coinbase";

const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';

class AccountManageCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            account: {},
            customer: {},
            topup: {
                currency_sign: '', 
                balance: 0.00,
                balance_held: 0.00,
                balance_debit: 0.00,
                balance_transferable: 0.00,
                has_saved_card: false,
                auto_topup_amount: 0.00,
                new_auto_topup_amount: "0.00",
                topup_amount_crypto: "50.00",
            }
        }

    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','ManageCard',Sentry.Severity.Info);
        mpoAccount.getAccount(this.onGetAccount, 'ManageCard');
    }

    onGetAccount = (response) => {

        if (response.data.ResponseCode === "AUTH") {
            this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
        } else if (response.data.ResponseCode === "SUCCESS") {
            if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-2)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-2)', Sentry.Severity.Warning);
            } else {
                this.props.updateStateWithCustomer(response.data.Response.customer, null);
                this.setState({
                    isLoading: false,
                    modules: {
                        standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                        rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                        topup: response.data.Response.account.is_topup_enabled === 1,
                        facebook: response.data.Response.customer.is_facebook_connected,
                        apple: response.data.Response.customer.is_apple_connected,
                        google: response.data.Response.customer.is_google_connected
                    },
                    customer: response.data.Response.customer,
                    account: response.data.Response.account,
                    topup: {
                        currency_sign: response.data.Response.account.currency_sign, 
                        balance: parseFloat(response.data.Response.customer.balance),
                        balance_held: parseFloat(response.data.Response.customer.balance_held),
                        balance_debit: parseFloat(response.data.Response.customer.balance_debit),
                        balance_transferable: parseFloat(response.data.Response.customer.balance_transferable),
                        has_saved_card: response.data.Response.customer.has_saved_card,
                        auto_topup_amount: parseFloat(response.data.Response.customer.auto_topup_amount),
                        //new_auto_topup_amount: response.data.Response.customer.auto_topup_amount !== "0.00" ? response.data.Response.customer.auto_topup_amount : "30.00",
                        new_auto_topup_amount: response.data.Response.customer.auto_topup_amount,
                        topup_amount_crypto: "50.00",
                    },
                });
            }
        } else {
            //response.data.ResponseCode === "ERROR"
        }
    }

    showConfirmRemoveCard = () => {
        let confirmMessage = 'Are you sure you wish to remove this card from your account?';
        if (this.state.modules.standing_orders) {
            confirmMessage += '<br/>Any standing orders you have set up will be paused until you add a new card.';
        }
        mobiscroll.confirm({
            title: 'Remove Card',
            message: confirmMessage,
            okText: 'Yes',
            cancelText: 'No',
            callback: (res) => {
                if (res) {
                    this.removeCard();
                }
            }
        });
    }

    removeCard = () => {
        this.changeBilling(1,0);
    }

    setTopupAmount = (event, inst) => {
        //console.log(event, inst);
        //console.log(inst.getVal());
        this.setState({topup: updateObject(this.state.topup, {
                new_auto_topup_amount: inst.getVal()
            })
        });
    }

    saveTopupAmount = () => {
        this.changeBilling(0,this.state.topup.new_auto_topup_amount);
    }

    setTopupAmountCrypto = (event, inst) => {
        //console.log(event, inst);
        //console.log(inst.getVal());
        this.setState({topup: updateObject(this.state.topup, {
                topup_amount_crypto: inst.getVal()
            })
        });
    }

    changeBilling = (remove_card, topup_amount) => {

        const data = {
            RequestAction: 'ChangeBilling',
            remove_card: remove_card,
            topup_amount: topup_amount,
        };
        mpoSentry.addBreadcrumb('action',data.RequestAction,Sentry.Severity.Info);

        axiosInstance.post(null, data)
        .then(response => {
            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-1)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-1)', Sentry.Severity.Warning);
            } else if (response.data.ResponseCode === "SUCCESS") {
                mobiscroll.toast({message: 'Account updated', color: 'success'});
                mpoAccount.getAccount(this.onGetAccount, 'ManageCard');
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }
        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    coinbaseOnLoad = () => {
        logger('coinbaseOnLoad');
    }

    coinbaseOnChargeSuccess = (MessageData) => {
        logger('coinbaseOnChargeSuccess');
        logger(MessageData);
        mobiscroll.toast({
            message: 'Thank you for your payment. Cryptocurrency payments can take several minutes to be validated by the relevant blockchain. You will receive a notification from us when the transaction has been completed and your account has been credited..',
            duration: 15000,
            color: 'success'
        });
        //mpoAccount.getAccount(this.onGetAccount);
    }
    coinbaseOnChargeFailure = (MessageData) => {
        logger('coinbaseOnChargeFailure');
        logger(MessageData);
        mobiscroll.toast({message: 'Payment failed.', color: 'danger'});
    }
    coinbaseOnPaymentDetected = (MessageData ) => {
        logger('coinbaseOnPaymentDetected');
        logger(MessageData);
        //mobiscroll.toast({message: 'Thank you for your payment.', color: 'success'});
        //mpoAccount.getAccount(this.onGetAccount);
    }
    coinbaseOnModalClosed = () => {
        logger('coinbaseOnModalClosed');
    }

    topupWithCrypto = () => {

        mobiscroll.toast({
            message: 'Requesting Coinbase payment...',
            duration: 3000,
            display: 'center',
            color: 'info'
        });
        let returnurl = window.location.protocol + '//' + window.location.hostname + '/#' + this.props.history.location.pathname;
        if (process.env.NODE_ENV !== 'production') {
            if (window.location.port !== 80 && window.location.port !== 443) {
                returnurl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/#' + this.props.history.location.pathname;
            }
        }
        let data = {
            RequestAction: 'TopupCoinbase',
            return_url: returnurl,
            topup_amount: this.state.topup.topup_amount_crypto
        }

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        axiosInstance.post(null, data)
            .then(response => {
                logger(response);
                mobiscroll.notification.dismiss();
                if (response.data.ResponseCode === "SUCCESS") {
                    if (response.data.Response.hasOwnProperty('payment_method') && response.data.Response.payment_method === "CB" &&
                        response.data.Response.hasOwnProperty('payment_redirect') && response.data.Response.payment_redirect !== "") {
                        mpoSentry.addBreadcrumb('managecard','Coinbase redirect',Sentry.Severity.Info);
                        if (isCordova()) {
                            mpoCoinbase._inapp_browser_result = null;
                            mpoCoinbase._inapp_browser = openWindow(response.data.Response.payment_redirect, '_blank', 'location=no', isCustomApp);
                            mpoCoinbase._inapp_browser.addEventListener('loadstop', mpoCoinbase._in_app_browser_load_stop);
                            mpoCoinbase._inapp_browser.addEventListener('loaderror', mpoCoinbase._in_app_browser_load_error);
                            mpoCoinbase._inapp_browser.addEventListener('exit', this._in_app_browser_exit_coinbase);
                        } else {
                            mobiscroll.notification.dismiss();

                            // would prefer to open in iframe overlay
                            /*
                            mobiscroll.toast({
                                message: "Contacting Coinbase...",
                                duration: 15000,
                                display: 'center',
                                color: 'info'
                            });
                            window.location.replace(response.data.Response.payment_redirect);
                             */

                            mobiscroll.toast({
                                message: "Opening Coinbase...",
                                duration: 5000,
                                display: 'center',
                                color: 'info'
                            });
                            openWindow(response.data.Response.payment_redirect, '_system', '')
                        }
                    } else {
                        mobiscroll.toast({message: 'Error redirecting to Coinbase', color: 'error'});
                    }
                } else {
                    let errorMsg = response.data.Response[0];
                    mobiscroll.toast({message: errorMsg, duration: 6000, color: 'danger'});
                    mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);
                }
            })
            .catch(error => {
                mobiscroll.notification.dismiss();
                logger(error);
                this.setState({
                    toppingUp: false
                });
                mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                mpoSentry.captureException(error);
            });
    }

    render = () => {
        if (this.state.isLoading) {
            return (
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                    <div className="mbsc-form-group">
                        <div className="mbsc-form-group-title">Loading...</div>
                        </div>
                    </div>
                </mobiscroll.Form>
            );
        }

        const currencySign = this.state.account.currency_sign;
        const topupAmountsSavedCard = [
            {id: 0, val: "0.00", amount: currencySign+"0.00"},
            {id: 1, val: "10.00", amount: currencySign+"10.00"},
            {id: 2, val: "20.00", amount: currencySign+"20.00"},
            {id: 3, val: "30.00", amount: currencySign+"30.00"},
            {id: 4, val: "40.00", amount: currencySign+"40.00"},
            {id: 5, val: "50.00", amount: currencySign+"50.00"},
            {id: 6, val: "100.00", amount: currencySign+"100.00"},
            {id: 7, val: "150.00", amount: currencySign+"150.00"},
            {id: 8, val: "200.00", amount: currencySign+"200.00"},
            {id: 9, val: "250.00", amount: currencySign+"250.00"},
        ];
        const topupAmountsCrypto = [
            {id: 0, val: "5.00", amount: currencySign+"5.00"},
            {id: 1, val: "10.00", amount: currencySign+"10.00"},
            {id: 2, val: "20.00", amount: currencySign+"20.00"},
            {id: 3, val: "30.00", amount: currencySign+"30.00"},
            {id: 5, val: "50.00", amount: currencySign+"50.00"},
            {id: 6, val: "100.00", amount: currencySign+"100.00"},
            {id: 8, val: "200.00", amount: currencySign+"200.00"},
            {id: 9, val: "250.00", amount: currencySign+"250.00"},
            {id: 10, val: "500.00", amount: currencySign+"500.00"},
        ];

        const isCoinbaseEnabled = !isIframe() && parseInt(this.state.account.is_bitcoin_payments_enabled,10) === 1;
        const coinbaseMetadata = {
            env: process.env.NODE_ENV,
            app_id: process.env.REACT_APP_APP_ID,
            email: this.state.customer.email,
            customer_id: this.state.customer.id,
            customer_name: this.state.customer.name,
            customer_email: this.state.customer.email,
            source: 'ManageCard'
        };

        return (
            <React.Fragment>
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <Helmet>
                            <title>{process.env.REACT_APP_APP_TITLE} Billing</title>
                        </Helmet>

                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Saved Card</div>

                            {this.state.customer.has_saved_card ?
                            <React.Fragment>
                                <mobiscroll.Note color="info">Your saved card is {this.state.customer.credit_card_type} ending {this.state.customer.credit_card_ending}. To change your card, remove it and then add another.</mobiscroll.Note>
                                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.showConfirmRemoveCard(); }}>Remove Card</mobiscroll.Button>
                                </div>
                            </React.Fragment>
                            :
                            (parseInt(this.state.account.is_credit_card_tokenization_enabled, 10) === 1 ?
                            <React.Fragment>
                                <mobiscroll.Note color="info">You do not have a saved credit/debit card. You can add a card now to securely save it to your account and use it whenever you make a purchase to speed up the checkout process.</mobiscroll.Note>
                                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/addcard') }}>Add Card</mobiscroll.Button>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <mobiscroll.Note color="info">You do not have a saved credit/debit card. To save a card to your account, use the New Card option at Checkout next time you place an order, and tick the box to securely save the card.</mobiscroll.Note>
                            </React.Fragment>
                            )
                            }
                        </div>

                        {this.state.modules.topup ?
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Auto Topup</div>

                            {this.state.customer.has_saved_card ?
                            <React.Fragment>
                                {this.state.topup.auto_topup_amount > 0 ?
                                <mobiscroll.Note color="info">Automatic topup is currently enabled on your account. To disable automatic topup, change your nominated topup amount to {currencySign}0.00 and tap Save Changes.</mobiscroll.Note>
                                :
                                <mobiscroll.Note color="info">Automatic topup is currently not enabled on your account. To enable automatic topup whenever your account balance goes below {currencySign}5, nominate a topup amount and tap Save Changes.</mobiscroll.Note>
                                }

                                <label>
                                    Topup amount
                                    <mobiscroll.Select
                                        select="single"
                                        value={this.state.topup.new_auto_topup_amount}
                                        onSet={this.setTopupAmount}
                                    >
                                        {topupAmountsSavedCard.map((amt, idx) => <option key={amt.id} value={amt.val}>{amt.amount}</option>)}
                                    </mobiscroll.Select>
                                </label>

                                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.saveTopupAmount(); }}>Save Changes</mobiscroll.Button>
                                </div>

                            </React.Fragment>
                            : 
                            <React.Fragment>
                                <mobiscroll.Note color="info">You do not have a saved credit/debit card. Top up now with a new card to save it to your account and use your credit whenever you make a purchase to speed up the checkout process.</mobiscroll.Note>
                                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/topup') }}>Topup Now</mobiscroll.Button>
                                </div>
                            </React.Fragment>
                            }
                        </div>
                        : null}

                        {isCoinbaseEnabled ?
                            <div className="mbsc-form-group">
                                <div className="mbsc-form-group-title">Topup with Cryptocurrency</div>
                                <mobiscroll.Note color="info">
                                    You can now securely topup your account with Bitcoin, Ethereum, Litecoin and more. Payments are processed by Coinbase Commerce and you can use your credit whenever you make a purchase to speed up the checkout process.<br />
                                </mobiscroll.Note>

                                {isCordova() || isCustomApp ?
                                    <React.Fragment>
                                        <label>
                                            Topup amount
                                            <mobiscroll.Select
                                                select="single"
                                                value={this.state.topup.topup_amount_crypto}
                                                onSet={this.setTopupAmountCrypto}
                                            >
                                                {topupAmountsCrypto.map((amt, idx) => <option key={amt.id}
                                                                                              value={amt.val}>{amt.amount}</option>)}
                                            </mobiscroll.Select>
                                        </label>

                                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                            <mobiscroll.Button block={true} onClick={(e) => {
                                                e.preventDefault();
                                                this.topupWithCrypto();
                                            }}>Topup with Crypto
                                            </mobiscroll.Button>
                                        </div>

                                        <div className="mbsc-align-center mbsc-txt-s">Cryptocurrency payments can take several minutes to be validated by the relevant blockchain. You will receive a notification from us when the transaction has been completed and your account has been credited.</div>

                                    </React.Fragment>
                                    :
                                    <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                            <CoinbaseCommerceButton children="Topup $5 with Crypto"
                                                                    className={'mbsc-btn-block'}
                                                                    checkoutId={'307835bf-c0e0-43e2-8b68-89355a4e305d'}
                                                                    customMetadata={JSON.stringify(coinbaseMetadata)}
                                                                    onLoad={this.coinbaseOnLoad}
                                                                    onChargeSuccess={this.coinbaseOnChargeSuccess}
                                                                    onChargeFailure={this.coinbaseOnChargeFailure}
                                                                    onPaymentDetected={this.coinbaseOnPaymentDetected}
                                                                    onModalClosed={this.coinbaseOnModalClosed}/>
                                            <CoinbaseCommerceButton children="Topup $50 with Crypto"
                                                                    className={'mbsc-btn-block'}
                                                                    checkoutId={'42a239d0-0176-4835-b6e9-75945f697ccd'}
                                                                    customMetadata={JSON.stringify(coinbaseMetadata)}
                                                                    onLoad={this.coinbaseOnLoad}
                                                                    onChargeSuccess={this.coinbaseOnChargeSuccess}
                                                                    onChargeFailure={this.coinbaseOnChargeFailure}
                                                                    onPaymentDetected={this.coinbaseOnPaymentDetected}
                                                                    onModalClosed={this.coinbaseOnModalClosed}/>
                                            <CoinbaseCommerceButton children="Topup $100 with Crypto"
                                                                    className={'mbsc-btn-block'}
                                                                    checkoutId={'64d1c28a-1b81-415d-934a-cc4941fa9ca1'}
                                                                    customMetadata={JSON.stringify(coinbaseMetadata)}
                                                                    onLoad={this.coinbaseOnLoad}
                                                                    onChargeSuccess={this.coinbaseOnChargeSuccess}
                                                                    onChargeFailure={this.coinbaseOnChargeFailure}
                                                                    onPaymentDetected={this.coinbaseOnPaymentDetected}
                                                                    onModalClosed={this.coinbaseOnModalClosed}/>
                                            <CoinbaseCommerceButton children="Topup $250 with Crypto"
                                                                    className={'mbsc-btn-block'}
                                                                    checkoutId={'f0149222-07ee-44e6-a7ed-d42d9a121536'}
                                                                    customMetadata={JSON.stringify(coinbaseMetadata)}
                                                                    onLoad={this.coinbaseOnLoad}
                                                                    onChargeSuccess={this.coinbaseOnChargeSuccess}
                                                                    onChargeFailure={this.coinbaseOnChargeFailure}
                                                                    onPaymentDetected={this.coinbaseOnPaymentDetected}
                                                                    onModalClosed={this.coinbaseOnModalClosed}/>
                                            <CoinbaseCommerceButton children="Topup $500 with Crypto"
                                                                    className={'mbsc-btn-block'}
                                                                    checkoutId={'7d11c864-8cdb-44a0-b18a-818f6e43a591'}
                                                                    customMetadata={JSON.stringify(coinbaseMetadata)}
                                                                    onLoad={this.coinbaseOnLoad}
                                                                    onChargeSuccess={this.coinbaseOnChargeSuccess}
                                                                    onChargeFailure={this.coinbaseOnChargeFailure}
                                                                    onPaymentDetected={this.coinbaseOnPaymentDetected}
                                                                    onModalClosed={this.coinbaseOnModalClosed}/>
                                    </div>
                                }

                            </div>
                        : null}

                        {this.state.modules.topup || this.state.topup.balance > 0 || this.state.topup.balance_debit > 0 ?
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Account Balance</div>
                            <mobiscroll.Input 
                                disabled={true}
                                type="text" 
                                defaultValue={this.state.topup.currency_sign+parseFloat(this.state.topup.balance).toFixed(2)}>Credit</mobiscroll.Input>
                            {this.state.topup.balance_debit > 0 ? 
                            <mobiscroll.Input 
                                disabled={true}
                                type="text" 
                                defaultValue={this.state.topup.currency_sign+parseFloat(this.state.topup.balance_debit).toFixed(2)}>Debit</mobiscroll.Input>
                            : null }
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <mobiscroll.Button block={true} disabled={this.state.topup.balance === 0} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/withdraw') }}>Withdraw Funds</mobiscroll.Button>
                            </div>
                        </div>
                        : null}
                    </div>
                </mobiscroll.Form>

                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
            </React.Fragment>
        );

    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account/managecard';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountManageCard));
