import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import mobiscroll from "@mobiscroll/react";
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../lib/Sentry';
import { logOutput, nl2br} from "../shared/utility";
import {mpoOneSignal} from "../lib/OneSignal";

const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';

class Tests extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Tests',Sentry.Severity.Info);
    }

    render = () => {
        return (
            <div className="app-tab">
                <Helmet>
                    <title>Tests - {process.env.REACT_APP_APP_TITLE}</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    <div className="mbsc-empty">
                        {debugMode ?
                            <React.Fragment>
                                <h3>Tests - {process.env.REACT_APP_APP_TITLE}</h3>

                                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); mpoOneSignal.getPermissionStatus(); }}>getPermissionStatus</mobiscroll.Button>
                                </div>

                                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); mpoOneSignal.TriggerInAppIosPrompt(); }}>TriggerInAppIosPrompt</mobiscroll.Button>
                                </div>

                            </React.Fragment>
                            :
                            <h3>Tests not enabled</h3>
                        }
                    </div>
                </mobiscroll.Form>
            </div>
        );
    }
}

export default Tests;