import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import mobiscroll from "@mobiscroll/react";
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../lib/Sentry';

//const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';

export default class Privacy extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Privacy',Sentry.Severity.Info);
    }

    render = () => {
        return (
            <div className="app-tab">
                <Helmet>
                    <title>Privacy Policy - {process.env.REACT_APP_APP_TITLE}</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    <h3>Privacy Policy</h3>
                    <div className="mbsc-txt-s">
                        {process.env.REACT_APP_APP_COMPANY && process.env.REACT_APP_APP_ABN ?
                            <p>{process.env.REACT_APP_APP_COMPANY} (ABN {process.env.REACT_APP_APP_ABN})</p>
                            :
                            <p>MYPREORDER PTY LTD (ABN 87 611 967 376)</p>
                        }
                        <p>This Privacy Policy applies to all products, services and websites owned by us. We are extremely concerned to protect your privacy and confidentiality. If you have any questions regarding this Privacy Policy then please contact us.</p>

                        <h4>Information we collect</h4>
                        <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. Information that we may collect include the following:</p>
                        <p>If you do not want us to collect, store or use your information, then you should not use our products and services.</p>
                        <p>The app uses third party services that may also collect information: Google Play Services, Amazon Web Services, Facebook, PayPal, Apple, Google Analytics, Firebase, Net Payments, Pin Payments, Fat Zebra, Coinbase Commerce, One Signal, MailChimp, MailerLite, Sentry, Hotjar.</p>

                        <h4>How we use your information</h4>
                        <p>Information provided by you may be used:<br/>
                            <ul>
                                <li>To personalise your experience when using our products, services and websites</li>
                                <li>To maintain our accounts</li>
                                <li>To provide you with the services which you request including processing orders and payments</li>
                                <li>To answer your enquiries</li>
                                <li>To verify your identity for security purposes</li>
                                <li>To contact you regarding your order, news and promotions on offer</li>
                                <li>By merchants that you have ordered services from to contact you regarding your order, news and promotions on offer</li>
                                <li>To improve our products, services and websites</li>
                            </ul>
                        </p>
                        <p>Your information will never be shared with third parties, except in situations where a third parties services are employed by us in operating and conducting business, and under the condition that they too treat the information confidentially. Access to data is restricted to our employees and contractors on a need-to-know basis. We do not sell your data.</p>

                        <h4>How we protect your information</h4>
                        <p>
                            <ul>
                                <li>We use Strong SSL Encryption to transmit sensitive data.</li>
                                <li>We use a PCI Compliant Payment Gateway to process credit/debit card details and payments. Credit/Debit Card data is encrypted and stored in the Payment Gateway providers data vault, is only accessible by authorised personnel, and is never stored on our servers.</li>
                                <li>Your account is password protected by a password of your choosing. It is your responsibility to use a unique, strong password and to keep this password confidential.</li>
                                <li>Security of any data transmitted through the Internet can never be 100% guaranteed, therefore you use our products, services, and website entirely at your own risk.</li>
                            </ul>
                        </p>
                        <h4>How to review, change or remove your data</h4>
                        <p>You may sign in to your account at any time to review and update your data. To close your account and remove your data contact support from the email address that is registered to your account. Verified requests will be responded to within 30 days. Inactive accounts may be automatically disabled or erased according to law. For any other queries please contact support.</p>
                        <h4>Compulsory disclosure</h4>
                        <p>Like any business, we will be obliged to divulge your personal information if requested by a government agency with the proper authorisation, such as a court order.</p>
                        <h4>Third party Websites</h4>
                        <p>We are not responsible or liable for the content or activities of any third party Websites that we may link to or consume data from. If you have any questions in this regard, or believe that a Website that we are linked to is not within the context of the products and services we offer, then please contact us.</p>
                        <h4>Changes to this Privacy Policy</h4>
                        <p>This Privacy Policy may be altered from time to time and the changes will be published on this page. It is recommended that you check this page periodically for any changes.</p>
                        <p className="mbsc-txt-muted">Last updated: 1st May 2021</p>
                    </div>
                </mobiscroll.Form>
            </div>
        );
    }
}