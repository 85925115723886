import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import MerchantGroupCard from '../../components/merchant/MerchantGroupCard';
import MerchantCard from '../../components/merchant/MerchantCard';
import MerchantCardSimple from '../../components/merchant/MerchantCardSimple';
//import LocationsBottomNav from './BottomNav';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import {PullToRefresh, PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";
import * as actions from '../../store/actions/index';
import {logger, updateObject, isGoogleMapsLoaded, nl2br, isIframe, isCordova, attachDeviceInfoToData} from '../../shared/utility';
import {mpoAppStore} from "../../lib/AppStore";
import {Helmet} from "react-helmet";
import {mpoUpgrade} from "../../lib/Upgrade";
import {mpoOneSignal} from "../../lib/OneSignal";

const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';
const isResellerApp = process.env.REACT_APP_RESELLER_APP === 'true';
const useSimpleMerchantCard = isCustomApp && process.env.REACT_APP_USE_SIMPLE_MERCHANT_CARD === 'true';
const allowSearch = !isCustomApp || isResellerApp;

class Locations extends Component {

    constructor(props) {

        //logger('Locations constructor');

        super(props);
        //logger(props);

        let isLoading = true;

        /*
        const cacheMins = isProduction() ? -10 : -1;
        if (this.props.merchants !== undefined && this.props.merchants.length > 0 &&
            this.props.settings !== undefined && this.props.settings.hasOwnProperty('local_ts') && this.props.settings.local_ts > unixUtcTimeOffsetMins(cacheMins)) {
            isLoading = false;
        }
        */
    //    this.props.updateStateWithMerchants([]);
    //    this.props.updateStateWithMerchantsNearby([]);
    //    this.props.updateStateWithMerchantsSearch([]);
    //    this.props.updateStateWithMerchantsHistory([]);
        /*
        if (this.props.merchants_nearby === undefined) {
            this.props.updateStateWithMerchantsNearby([]);
        }
        if (this.props.merchants_search === undefined) {
            this.props.updateStateWithMerchantsSearch([]);
        }
        if (this.props.merchants_fave === undefined) {
            this.props.updateStateWithMerchantsFave([]);
        }
        if (this.props.merchants_history === undefined) {
            this.props.updateStateWithMerchantsHistory([]);
        }
        */

        let locationGroup = props.hasOwnProperty('match') ? props.match.params.group : null;
        if (!locationGroup) {
            locationGroup = this.props.group;
        }

        this.state = {
            isLoading: isLoading,
            coords: null,
            isGeolocationAvailable: typeof (navigator) !== 'undefined' && 'geolocation' in navigator,
            isGeolocationEnabled: false,
            positionError: null,
            locationGroup: locationGroup,
            locationsFilter: "nearby",
            showSearch: allowSearch,
            placesSearchOptions: {
                componentRestrictions: {'country': ['AU','NZ']},
                types: ['(cities)']
            },
            //placesSearch: this.props.settings.hasOwnProperty('search') ? this.props.settings.search : ""
            placesSearch: "",
            placeSelected: false,
        }

        this.getLocation = this.getLocation.bind(this);
        this.handleChangeLocationGroup = this.handleChangeLocationGroup.bind(this);
    }

    setLocationGroup = (group) => {
        const updatedState = updateObject(this.state, {
            locationGroup: group
        });
        this.setState(updatedState);
    }

    handleChangeLocationGroup = (_Group) => {
        // console.log("Locations.handleChangeLocationGroup");
        // console.log(_Group);
        this.setLocationGroup(_Group);
    }

    onPositionError = (positionError) => {
        logger(positionError);

        if (allowSearch) {
            logger('gpe1a');
            const updatedState = updateObject(this.state, {
                isLoading: false,
                locationsFilter: this.props.user.auth.isLoggedIn && this.props.user.auth.isMember && this.props.merchants_history !== undefined && this.props.merchants_history.length > 0 ? "history" : "search",
                showSearch: true,
                coords: null,
                isGeolocationEnabled: false,
                positionError
            });
            this.setState(updatedState);
        } else {
            logger('gpe1b');
            const updatedState = updateObject(this.state, {
                coords: null,
                isGeolocationEnabled: false,
                positionError
            });
            this.setState(updatedState, this.postDataHandler);
        }

    }

    onPositionSuccess = (position) => {
        const updatedState = updateObject(this.state, {
            coords: position.coords,
            isGeolocationEnabled: true,
            positionError: null
        });
        this.setState(updatedState, () => {
            this.postDataHandler(position.coords.latitude, position.coords.longitude, null, 'nearby');
        });
    }

    getLocation = () => {
        //logger('getLocation called');
        //logger(navigator);
        if (typeof (navigator) !== 'undefined' && 'geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(this.onPositionSuccess, this.onPositionError, {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: Infinity,
            });
        }
    }

    componentDidMount = () => {
        //logger('Locations componentDidMount');
        //logger(this.props);
        mpoSentry.addBreadcrumb('nav','Locations',Sentry.Severity.Info);
        
        if (this.state.isLoading) {
            if (isGoogleMapsLoaded()) {
                logger('google maps loaded');
            } else {
                logger('google maps not loaded');
                mpoSentry.captureMessage('Google maps not loaded', Sentry.Severity.Warning);
            }
            this.getAccount();
            if (this.state.isGeolocationAvailable && !this.state.isGeolocationEnabled && this.state.positionError === null) {
                logger('cdm1');
                // todo: replace with in-app message (if cordova)
                // mpoOneSignal.TriggerInAppLocationPrompt();
                this.getLocation();
            } else if (!this.state.isGeolocationAvailable || !this.state.isGeolocationEnabled || this.state.positionError !== null) {
                if (allowSearch) {
                    logger('cdm1a');
                    const updatedState = updateObject(this.state, {
                        isLoading: false,
                        locationsFilter: this.props.user.auth.isLoggedIn && this.props.user.auth.isMember && this.props.merchants_history !== undefined && this.props.merchants_history.length > 0 ? "history" : "search",
                        showSearch: true
                    });
                    this.setState(updatedState);
                } else {
                    logger('cdm1b');
                    this.postDataHandler();    
                }
            } else if (this.state.coords !== null) {
                logger('cdm2');
                this.postDataHandler(this.state.coords.latitude, this.state.coords.longitude, null, 'nearby');
            } else {
                // flicks to history then back to nearby
                //logger('cdm3');
                //this.postDataHandler();
            }
        }
    }

    /*
    componentDidUpdate = (prevProps) => {
        logger('Locations componentDidUpdate');
        logger(this.props);
        //logger(prevProps);
        if (prevProps.coords !== this.props.coords || prevProps.positionError !== this.props.positionError) {
            logger('coords');
            //logger(this.props);
            if (this.state.isLoading) {
                //logger(this.props);
                if (!this.props.isGeolocationAvailable || !this.props.isGeolocationEnabled || this.props.positionError) {
                    logger('cdu3');
                    if (allowSearch) {
                        logger('cdu3a');
                        this.postDataHandler();
                    } else {
                        logger('cdu3b');
                        this.postDataHandler();
                    }
                } else if (this.props.coords) {
                    logger('cdu4');
                    this.postDataHandler(this.props.coords.latitude, this.props.coords.longitude, null, 'nearby');
                } else {
                    logger('cdu5');
                    this.postDataHandler();
                }
            } else if (!this.props.isGeolocationAvailable || !this.props.isGeolocationEnabled || this.props.positionError !== null) {
                logger('cdu6');
                if (allowSearch && this.state.locationsFilter !== "search") {
                    logger('cdu6a');
                    const updatedState = updateObject(this.state, {
                        locationsFilter: this.props.user.auth.isLoggedIn && this.props.user.auth.isMember && this.props.merchants_history !== undefined && this.props.merchants_history.length > 0 ? "history" : "search",
                        showSearch: true
                    });
                    this.setState(updatedState);
                } else {
                    logger('cdu6b');
                    this.postDataHandler();
                }
            } else if (this.props.coords !== null) {
                logger('cdu7');
                this.postDataHandler(this.props.coords.latitude, this.props.coords.longitude, null, 'nearby');
            } else {
                logger('cdu8');
                this.postDataHandler();
            }
        }
    }

    positionError = (err) => {
        logger('positionError');
        logger(err);
    }

    positionSuccess = (res) => {
        logger('positionSuccess');
        logger(res);
    }
    */

    postDataHandler = (lat = null, long = null, state = null, trigger = null) => {
        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: 'Loading...', duration: 3000, display: 'center', color: 'info'});

        if (state === null) {
            //state = this.props.settings.hasOwnProperty('state') ? this.props.settings.state : "";
        }
        const data = {
            RequestAction: 'Merchants',
            //set: this.props.settings.hasOwnProperty('set') ? this.props.settings.set : 'ALL',
            set: lat !== null && long !== null ? "COORDS" : (state !== null && state !== "" && !isCustomApp ? "STATE" : "ALL"),
            lat: lat,
            long: long,
            state: state,
            search: this.state.placesSearch
        };
        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }
        //logger(data);
        axiosInstance.post(null, data)
            .then(response => {
                mobiscroll.notification.dismiss();
                //logger(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    let locationGroup = this.state.locationGroup;
                    let locationsFilter = this.state.locationsFilter;
                    if (!allowSearch || trigger === null) {
                        this.props.updateStateWithMerchantsHistory(response.data.Response.history);
                        if (allowSearch) {
                            locationsFilter = this.props.user.auth.isLoggedIn && this.props.user.auth.isMember && response.data.Response.history.length > 0 ? "history" : "search";
                        } else {
                            this.props.updateStateWithMerchants(response.data.Response.merchants);
                        }
                    } else if (trigger === "nearby") {
                        this.props.updateStateWithMerchantsNearby(response.data.Response.merchants);
                        this.props.updateStateWithMerchantsHistory(response.data.Response.history);
                        locationsFilter = "nearby";
                        locationsFilter = response.data.Response.merchants.length > 0 ? "nearby" : 
                            (this.props.user.auth.isLoggedIn && this.props.user.auth.isMember && response.data.Response.history.length > 0 ? "history" : "search");
                    } else if (trigger === "search") {
                        this.props.updateStateWithMerchantsSearch(response.data.Response.merchants);
                        locationsFilter = "search";
                    }
                    this.props.updateStateWithMerchantTypes(response.data.Response.merchant_types);
                    this.props.updateStateWithMerchantGroups(response.data.Response.merchant_groups);
                    this.props.updateStateWithLocationSettings(response.data.Response.settings);

                    //logger(response.data.Response.merchants);
                    //logger(response.data.Response.merchants.length);
                    // let locationsFilter = this.state.locationsFilter;
                    // if (allowSearch && this.state.locationsFilter !== "search" && (lat === null || long === null || response.data.Response.merchants.length === 0)) {
                        //locationsFilter = "search";
                    // }
                    this.setState({
                        isLoading: false,
                        locationGroup: locationGroup,
                        locationsFilter: locationsFilter,
                        showSearch: allowSearch // && (this.state.locationsFilter === "search" || lat === null || long === null || response.data.Response.merchants.length === 0)
                    });
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                    this.setIsLoading(false);
                }
                mpoUpgrade.processUpgradeResponse(response);
            })
            .catch(error => {
                mobiscroll.notification.dismiss();
                //console.log(error);
                mobiscroll.toast({message: error, color: 'danger'});
                mpoSentry.captureException(error);
                this.setIsLoading(false);
            });
    }

    onPlacesSearchChange = (place) => {
        const updatedState = updateObject(this.state, {
            placesSearch: place,
            placeSelected: false
        });
        this.setState(updatedState);
    }

    onPlacesSearchSelect = (place) => {
        const updatedState = updateObject(this.state, {
            isLoading: true,
            placesSearch: place,
            placeSelected: true
        });
        this.setState(updatedState);
        
        geocodeByAddress(place)
            .then(results => {
                //console.log('geo', results); 
                const addr = {
                    geo: {},
                    state: "",
                    latlng: {},
                };
                if (results !== undefined && results[0] !== undefined) {
                    addr.geo = results[0];
                    if (results[0].address_components !== undefined) {
                        for (let a of results[0].address_components) {
                            if (a['types'][0] === 'administrative_area_level_1') {
                                addr.state = a['short_name'];
                                break;
                            }
                        }
                    }

                    getLatLng(results[0])
                        .then(latLng => {
                            //console.log('Success2', latLng)
                            addr.latlng = latLng;
                            this.postDataHandler(latLng.lat, latLng.lng, addr.state, 'search');
                        })
                        .catch(error => {
                            //console.error('Error2', error)
                            if (addr.state !== "") {
                                this.postDataHandler(null, null, addr.state, 'search');
                            } else {
                                this.setIsLoading(false);
                            }
                            mobiscroll.toast({message: "Error looking up place name (2)", color: 'danger'});
                            mpoSentry.captureException(error);
                        });
                } else {
                    this.setIsLoading(false);
                }
            })
            .catch(error => {
                //console.error('Error1', error)
                mobiscroll.toast({message: "Error looking up place name (1)", color: 'danger'});
                mpoSentry.captureException(error);
                this.setIsLoading(false);
            });
    }

    onAddressError = (status, clearSuggestions) => {
        mpoSentry.captureMessage('Google Maps API returned error with status: '+status, Sentry.Severity.Warning);
        clearSuggestions();
    }

    setLocationsFilter = (event) => {
        //logger('setLocationsFilter');
        //logger(this.refs);//.getLocation(); //{}
        const updatedState = updateObject(this.state, {
            locationsFilter: event.target.value
        });
        this.setState(updatedState);
        if (event.target.value === 'nearby' && (this.props.settings === undefined || !this.props.settings.hasOwnProperty('set') || this.props.settings.set === undefined || this.props.settings.set !== 'COORDS')) {
            logger('setLocationsFilter nearby reload');
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    }

    setIsLoading = (isLoading) => {
        const updatedState = updateObject(this.state, {
            isLoading: isLoading
        });
        this.setState(updatedState);
    }

    getAccount = () => {

        const data = {
            RequestAction: 'CustomerAccount'
        };

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        axiosInstance.post(null, data)
        .then(response => {
            //logger(response);
            if (response.data.ResponseCode === "AUTH") {
                if (this.props.user.customer.id !== 0) {
                    this.props.updateStateWithCustomer({id: 0, status: 0});
                }
                //mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                //mpoSentry.captureMessage('Session expired', Sentry.Severity.Warning);
            } else if (response.data.ResponseCode === "SUCCESS") {
                if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                    this.props.updateStateWithCustomer({id: 0, status: 0});
                    //mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                    //mpoSentry.captureMessage('Session expired', Sentry.Severity.Warning);
                } else {
                    // logged in
                }
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            //console.log(error);
            //mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mobiscroll.toast({message: error, color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    render = () => {
        
        //logger('Locations render');
        //logger(this.props);

        const headerImage = process.env.REACT_APP_HEADER_IMAGE;

        const placeholderMerchants = [
            {
                id: "merchant-placeholder1",
                key: "merchant-placeholder1",
                merchant_placeholder: 1
            },
            {
                id: "merchant-placeholder2",
                key: "merchant-placeholder2",
                merchant_placeholder: 1
            },
            {
                id: "merchant-placeholder3",
                key: "merchant-placeholder3",
                merchant_placeholder: 1
            }
        ];

        let locationGroupNav = null;

        let merchants = placeholderMerchants;
        let noResults = null;

        let downloadAppJsx = null;
        const appDownloadInfo = isCustomApp && !isIframe() && mobiscroll.platform.name !== 'ios' ? mpoAppStore.getAppDownloadInfo() : null;

        if (appDownloadInfo !== null && appDownloadInfo.downloadAppUrl !== "") {
            downloadAppJsx = <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                <mobiscroll.Button block={true} data-icon={appDownloadInfo.downloadAppIcon} onClick={(e) => { e.preventDefault(); mpoAppStore.downloadApp(appDownloadInfo.downloadAppUrl); }}>{appDownloadInfo.downloadAppText}</mobiscroll.Button>
            </div>
        }

        const promoText = this.props.settings.hasOwnProperty('promo_text') && this.props.settings.promo_text ? <mobiscroll.Note color="info"><span className="empty icon fas fa-bullhorn"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.settings.promo_text)}}></span></mobiscroll.Note> : null;
        const paymentText = this.props.settings.hasOwnProperty('payment_text') && this.props.settings.payment_text ? <mobiscroll.Note color="info"><span className="empty icon fas fa-credit-card"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.settings.payment_text)}}></span></mobiscroll.Note> : null;
        const deliveryText = this.props.settings.hasOwnProperty('delivery_text') && this.props.settings.delivery_text ? <mobiscroll.Note color="info"><span className="empty icon fas fa-motorcycle"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.settings.delivery_text)}}></span></mobiscroll.Note> : null;
        const warningText = this.props.settings.hasOwnProperty('warning_text') && this.props.settings.warning_text ? <mobiscroll.Note color="warning"><span className="empty icon fas fa-exclamation-triangle"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.settings.warning_text)}}></span></mobiscroll.Note> : null;

        if (!this.state.isLoading) {
            if (allowSearch) {
                switch (this.state.locationsFilter) {
                    case "nearby":
                        merchants = this.props.merchants_nearby;
                        noResults = <div>
                            <h3>No results nearby :(</h3>

                            {(!this.state.isGeolocationAvailable || !this.state.isGeolocationEnabled || this.state.positionError !== null) ?
                                <mobiscroll.Note color="danger"><b>Geolocation not enabled or unavailable.</b><br />Allow access to your location to display stores that are nearest to you.</mobiscroll.Note>
                            : null}

                            {this.state.showSearch ? <p>Search by City or Suburb</p> : null }
                        </div>
                        break;
                    case "search":
                        merchants = this.props.merchants_search;
                        let txt = "";
                        if (this.state.placeSelected) {
                            txt = <h3>No results :(</h3>
                        } else if (this.state.placesSearch === "") {
                            txt = <p>(start typing)</p>;
                        } else if (this.state.placesSearch.length >= 5) {
                            txt = <p>(select a location)</p>;
                        } else if (this.state.placesSearch.length < 5) {
                            txt = <p>(keep typing)</p>;
                        }
                        noResults = <div>{txt}</div>
                        break;
                    case "fave":
                        merchants = this.props.merchants_fave;
                        if (this.props.user.auth.isLoggedIn && this.props.user.auth.isMember) {
                            noResults = <div>
                                <h3>No favourites :(</h3>
                            </div>
                        } else {
                            noResults = <div>
                                <p></p>
                            </div>
                        }
                        break;
                    case "history":
                        merchants = this.props.merchants_history;
                        if (this.props.user.auth.isLoggedIn && this.props.user.auth.isMember) {
                            noResults = <div>
                                <h3>No history found :(</h3>
                            </div>
                        } else {
                            noResults = <div>
                                <p><a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push('/locations/login') }}>Sign in</a> to view your history</p>
                            </div>
                        }
                        break;
                }
            } else {
                merchants = this.props.merchants;
                noResults = <div>
                    <h3>No results :(</h3>
                </div>
            }

            if (this.state.locationGroup) {

                locationGroupNav = <mobiscroll.TabNav display="inline" ref="locationGroupNav">
                    {this.props.merchant_groups.map((group) => {
                        return <mobiscroll.NavItem key={group.id}
                                                   id={group.id}
                                                   replace={true}
                                                   isActive={() => group.code === this.state.locationGroup}
                                                   selected={group.code === this.state.locationGroup}
                                                   onClick={this.setLocationGroup.bind(this, group.code)}
                        >
                            {group.group_title}
                        </mobiscroll.NavItem>
                    })}
                </mobiscroll.TabNav>

                const locationGroupData = this.props.merchant_groups.filter((group) => {
                    //logger(group.code);
                    return group.code === this.state.locationGroup;
                });
                //logger(locationGroupData);
                //logger(locationGroupData[0].merchants);
                merchants = merchants.filter((merchant) => {
                    //logger(merchant.id + ' = ' + locationGroupData[0].merchants.includes(merchant.id));
                    return locationGroupData[0].merchants.includes(merchant.id);
                })
            }
        }

        return (
            <div>
            <mobiscroll.Form className="mpo-form-width-responsive app-tab-locations">
                <div className="app-tab">
                    <Helmet>
                        <title>{process.env.REACT_APP_APP_TITLE}</title>
                    </Helmet>
                    <PullToRefresh
                        pullDownContent={<PullDownContent />}
                        releaseContent={<ReleaseContent />}
                        refreshContent={<RefreshContent />}
                        pullDownThreshold={100}
                        onRefresh={() => new Promise((resolve) => {
                            window.location.reload();
                            resolve();
                        })}
                        triggerHeight={500}
                        startInvisible={true}>

                        {headerImage !== 'undefined' && headerImage !== '' ? <div className="locations-header-image-div"><img className="locations-header-image mbsc-media-fluid" src={headerImage} alt="" /></div> : null}

                    { this.state.showSearch ?
                    <React.Fragment>
                        <mobiscroll.FormGroup style={{margin: 0}}>
                            <mobiscroll.Segmented name="locationsFilter" value="nearby" icon="empty icon fas fa-compass" checked={this.state.locationsFilter === "nearby"} onChange={this.setLocationsFilter}> Nearby</mobiscroll.Segmented>
                            <mobiscroll.Segmented name="locationsFilter" value="search" icon="empty icon fas fa-search" checked={this.state.locationsFilter === "search"} onChange={this.setLocationsFilter}> Search</mobiscroll.Segmented>
                            {/*<mobiscroll.Segmented name="locationsFilter" value="fave" icon="empty icon fas fa-heart" checked={this.state.locationsFilter === "fave"} onChange={this.setLocationsFilter}> Favourites</mobiscroll.Segmented>*/}
                            <mobiscroll.Segmented name="locationsFilter" value="history" icon="empty icon fas fa-clock" checked={this.state.locationsFilter === "history"} onChange={this.setLocationsFilter}> History</mobiscroll.Segmented>
                        </mobiscroll.FormGroup>
                        
                        {this.state.locationsFilter === "nearby" && (!this.state.isGeolocationAvailable || this.state.positionError) ?
                            <mobiscroll.Note color="danger"><b>Geolocation not enabled or unavailable.</b><br />Allow access to your location to display stores that are nearest to you.</mobiscroll.Note>
                        : null}

                        {/*this.state.locationsFilter === "nearby" && this.state.isGeolocationAvailable && !this.state.isGeolocationEnabled && this.state.positionError === null ?
                        <mobiscroll.Note color="info">Allow access to your location to display stores that are nearest to you.</mobiscroll.Note>
                        : null*/}

                        {this.state.locationsFilter === "search" ?
                        <mobiscroll.FormGroup style={{margin: 0}}>
                            <PlacesAutocomplete
                                value={this.state.placesSearch}
                                onChange={this.onPlacesSearchChange}
                                onSelect={this.onPlacesSearchSelect}
                                onError={this.onAddressError}
                                searchOptions={this.state.placesSearchOptions}
                                shouldFetchSuggestions={this.state.placesSearch.length > 3}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <React.Fragment>
                                    <mobiscroll.Input 
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input',
                                        })}
                                        placeholder="City or Suburb" 
                                        type="text" 
                                        icon="empty icon fas fa-search"
                                        name="searchPlaces" />
                                    <div className="autocomplete-dropdown-container">
                                    {loading && <div className="suggestion-item">Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active ? 'suggestion-item suggestion-item-active': 'suggestion-item';
                                        //console.log(suggestion);
                                        return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                            className
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </React.Fragment>
                                )}
                            </PlacesAutocomplete>

                        </mobiscroll.FormGroup>
                        : null }
                    </React.Fragment>
                    : null }

                    {merchants !== undefined && merchants.length > 0 ?
                    <React.Fragment>
                        {this.props.merchant_groups !== undefined && this.props.merchant_groups.length > 0 && (!this.state.locationGroup || this.state.locationGroup === null || this.state.locationGroup === '') ?
                            <React.Fragment>
                                {downloadAppJsx}
                                <div className="mbsc-grid">
                                    <div className="mbsc-row mbsc-justify-content-center mbsc-no-padding">
                                        {
                                            this.props.merchant_groups.map((group) => {
                                                return <MerchantGroupCard item={group} key={group.id} handleChangeLocationGroup={this.handleChangeLocationGroup}/>
                                            })
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {locationGroupNav}
                                {warningText}
                                {promoText}
                                {paymentText}
                                {deliveryText}
                                {useSimpleMerchantCard ? <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: '1em'}}>Please select your store below</p> : null}
                                <div className="mbsc-grid">
                                    <div className="mbsc-row mbsc-justify-content-center mbsc-no-padding">
                                        {
                                            merchants.map((merchant) => {
                                                return useSimpleMerchantCard ?
                                                    <MerchantCardSimple item={merchant} key={merchant.id}/> :
                                                    <MerchantCard item={merchant} key={merchant.id}/>
                                            })
                                        }
                                    </div>
                                </div>
                                {downloadAppJsx}
                            </React.Fragment>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {locationGroupNav}
                        <div className="mbsc-empty">
                            {noResults}
                        </div>
                    </React.Fragment>
                    }

                    {/* !isProduction() ?
                    <Footer />
                    <LocationsBottomNav />
                    : null */}
                    </PullToRefresh>
                </div>
            </mobiscroll.Form>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        merchants: state.merchants.merchants,
        merchants_nearby: state.merchants.merchants_nearby,
        merchants_search: state.merchants.merchants_search,
        merchants_fave: state.merchants.merchants_fave,
        merchants_history: state.merchants.merchants_history,
        merchant_types: state.merchants.merchant_types,
        merchant_groups: state.merchants.merchant_groups,
        settings: state.merchants.settings,
        user: state.user        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithLocationGroup: (group) => dispatch(actions.setLocationGroupAction(group)),
        updateStateWithMerchants: (merchants) => dispatch(actions.setMerchantsAction(merchants)),
        updateStateWithMerchantsNearby: (merchants) => dispatch(actions.setMerchantsNearbyAction(merchants)),
        updateStateWithMerchantsSearch: (merchants) => dispatch(actions.setMerchantsSearchAction(merchants)),
        updateStateWithMerchantsFave: (merchants) => dispatch(actions.setMerchantsFaveAction(merchants)),
        updateStateWithMerchantsHistory: (merchants) => dispatch(actions.setMerchantsHistoryAction(merchants)),
        updateStateWithMerchantTypes: (merchant_types) => dispatch(actions.setMerchantTypesAction(merchant_types)),
        updateStateWithMerchantGroups: (merchant_groups) => dispatch(actions.setMerchantGroupsAction(merchant_groups)),
        updateStateWithLocationSettings: (settings) => dispatch(actions.setLocationSettingsAction(settings)),
        updateStateWithCustomer: (customer) => dispatch(actions.setCustomerAction(customer, null, null))
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler( Locations, axiosInstance ));
//export default withRouter(geolocated({suppressLocationOnMount: suppressLocationOnMount, isOptimisticGeolocationEnabled: false, userDecisionTimeout: 30000})(connect(mapStateToProps, mapDispatchToProps)(Locations)));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Locations));
