import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import mobiscroll from "@mobiscroll/react";
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../lib/Sentry';
import {isCordova, openWindow} from "../shared/utility";

const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';
const termsUrl = process.env.REACT_APP_APP_TERMS_URL !== undefined && process.env.REACT_APP_APP_TERMS_URL !== null && process.env.REACT_APP_APP_TERMS_URL !== "" ? process.env.REACT_APP_APP_TERMS_URL : null;
const privacyUrl = process.env.REACT_APP_APP_PRIVACY_URL !== undefined && process.env.REACT_APP_APP_PRIVACY_URL !== null && process.env.REACT_APP_APP_PRIVACY_URL !== "" ? process.env.REACT_APP_APP_PRIVACY_URL : null;

class About extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','About',Sentry.Severity.Info);
    }

    render = () => {
        return (
            <div className="app-tab">
                <Helmet>
                    <title>About {process.env.REACT_APP_APP_TITLE}</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    <div className="mbsc-empty">
                        <h3>About {process.env.REACT_APP_APP_TITLE}</h3>
                        {process.env.REACT_APP_APP_DESCRIPTION !== null && process.env.REACT_APP_APP_DESCRIPTION !== "" ? <p>{process.env.REACT_APP_APP_DESCRIPTION}</p> : null }

                        {isCordova() && process.env.REACT_APP_CUSTOM_WEBSITE !== undefined && process.env.REACT_APP_CUSTOM_WEBSITE !== null && process.env.REACT_APP_CUSTOM_WEBSITE !== "" ?
                            <p><a href="#" onClick={(e) => { e.preventDefault(); openWindow(process.env.REACT_APP_CUSTOM_WEBSITE, '_system', ''); }}>{process.env.REACT_APP_CUSTOM_WEBSITE}</a></p> : null }

                        {!isCordova() && process.env.REACT_APP_CUSTOM_WEBSITE !== undefined && process.env.REACT_APP_CUSTOM_WEBSITE !== null && process.env.REACT_APP_CUSTOM_WEBSITE !== "" ?
                            <p><a href={process.env.REACT_APP_CUSTOM_WEBSITE} target="_blank">{process.env.REACT_APP_CUSTOM_WEBSITE}</a></p> : null }

                        {isCordova() ?
                            <p>Secure online payments processing by
                                {process.env.REACT_APP_CUSTOM_PAYMENTS !== undefined && process.env.REACT_APP_CUSTOM_PAYMENTS !== null && process.env.REACT_APP_CUSTOM_PAYMENTS !== "" ?
                                    <span> {process.env.REACT_APP_CUSTOM_PAYMENTS}</span>
                                    :
                                    <span><br/><a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        openWindow("https://www.netpayments.com.au", '_system', '');
                                    }}>Net Payments</a></span>
                                }
                            </p>
                            :
                            <p>Secure online payments processing by
                                {process.env.REACT_APP_CUSTOM_PAYMENTS !== undefined && process.env.REACT_APP_CUSTOM_PAYMENTS !== null && process.env.REACT_APP_CUSTOM_PAYMENTS !== "" ?
                                    <span> {process.env.REACT_APP_CUSTOM_PAYMENTS}</span>
                                    :
                                    <span><br/><a href="https://www.netpayments.com.au" target="_blank">Net Payments</a></span>
                                }
                            </p>
                        }

                        {isCordova() ?
                            <p>Software by <a href="#" onClick={(e) => {
                                e.preventDefault();
                                openWindow("https://www.mypreorder.com.au", '_system', '');
                            }}>mypreorder online ordering and branded apps</a></p>
                            :
                            <p>Software by <a href="https://www.mypreorder.com.au" target="_blank">mypreorder online ordering and branded apps</a></p>
                        }

                        <p>Software Version: {process.env.REACT_APP_APP_CUSTOM_VERSION ? process.env.REACT_APP_APP_CUSTOM_VERSION : process.env.REACT_APP_APP_VERSION}{debugMode ? "-DEBUG" : null}</p>

                        {termsUrl === null || privacyUrl === null ?
                            <p><a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/terms');
                            }}>Terms &amp; Refund Policy</a> | <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/privacy');
                            }}>Privacy Policy</a></p>
                            :
                            (isCordova() ?
                                <p><a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openWindow(termsUrl, '_system', '');
                                }}>Terms &amp; Refund Policy</a> | <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openWindow(privacyUrl, '_system', '');
                                }}>Privacy Policy</a></p>
                                :
                                <p><a href={termsUrl} target="_blank">Terms &amp; Refund Policy</a> | <a href={privacyUrl} target="_blank">Privacy Policy</a></p>
                            )
                        }

                    </div>
                </mobiscroll.Form>
            </div>
        );
    }
}

export default About;