import React, { Component } from 'react';
//import { Switch, BrowserRouter, HashRouter, Route, Redirect, withRouter } from 'react-router-dom';
import { Switch, HashRouter, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {logger, isCordova, isProduction, openWindow, isIframe} from './shared/utility';
import { mpoOneSignal } from './lib/OneSignal';
import { mpoFirebase } from "./lib/Firebase";
import { mpoThirdPartyUser } from "./lib/ThirdPartyUser";

import './App.css';
import './lib/fontawesome-free/css/all.css';
//import './lib/fontawesome-free/js/all.js';
import mobiscroll from "@mobiscroll/react";
import * as Sentry from '@sentry/browser';
import { mpoSentry } from './lib/Sentry';
import { Offline, Online } from "react-detect-offline";
import {PullToRefresh} from "react-js-pull-to-refresh";
import {PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";

import * as actions from './store/actions/index';

import Locations from './containers/Locations/Locations';
import Menu from './containers/Menu/Menu';
import Checkout from './containers/Checkout';
import Cart from './containers/Cart';
import Account from './containers/Account/Account';
import AccountForgot from './containers/Account/Forgot';
import AccountLogin from './containers/Account/Login';
import AccountLogout from './containers/Account/Logout';
import AccountOrders from './containers/Account/Orders';
import AccountRewards from './containers/Account/Rewards';
import AccountTopup from './containers/Account/Topup';
import AccountAddCard from './containers/Account/AddCard';
import AccountStandingOrder from './containers/Account/StandingOrder';
import AccountManageCard from './containers/Account/ManageCard';
import AccountBilling from './containers/Account/Billing';
import AccountDeliveries from './containers/Account/Deliveries';
import AccountInvoices from './containers/Account/Invoices';
import AccountWithdraw from './containers/Account/Withdraw';
import AccountChangePassword from './containers/Account/ChangePassword';
import AccountResetPassword from './containers/Account/ResetPassword';
import AccountChangePin from './containers/Account/ChangePin';
import AccountSettings from './containers/Account/Settings';
import AccountFeedback from './containers/Account/Feedback';
import AccountNotifications from './containers/Account/Notifications';
import queryString from "query-string";
import Logs from "./containers/Logs";
import Tests from "./containers/Tests";
import About from "./containers/About";
import {mpoSession} from "./lib/Session";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import {mpoAppStore} from "./lib/AppStore";
import Cookies from "universal-cookie";

const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';

const cookies = new Cookies();
const sessionId = cookies.get(process.env.REACT_APP_APP_ID+'-sid');
const pingUrl = process.env.REACT_APP_PING_URL+(sessionId !== undefined && sessionId !== '' ? '?sid='+sessionId : '');
const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';
const customBrand = isCustomApp ? process.env.REACT_APP_CUSTOM_BRAND : '';
const isSingleStore = isCustomApp && process.env.REACT_APP_SINGLE_STORE === 'true';
const singleStoreMenuSlug = isSingleStore ? process.env.REACT_APP_SINGLE_STORE_MENU_SLUG : '';
if (isCustomApp && customBrand !== 'undefined' && customBrand !== '') {
    /*eslint-disable no-unused-expressions*/
    import('./assets/custom/'+customBrand+'/App.css');
    import('./assets/custom/'+customBrand+'/theme.css');
    /*eslint-enable no-unused-expressions*/
}

//const isResellerApp = process.env.REACT_APP_RESELLER_APP !== undefined && process.env.REACT_APP_RESELLER_APP === 'true';
const hideHome = process.env.REACT_APP_HIDE_HOME !== undefined && process.env.REACT_APP_HIDE_HOME === 'true';
const hideAbout = process.env.REACT_APP_HIDE_ABOUT !== undefined && process.env.REACT_APP_HIDE_ABOUT === 'true';
const hideAccount = process.env.REACT_APP_HIDE_ACCOUNT !== undefined && process.env.REACT_APP_HIDE_ACCOUNT === 'true';
//const hideSecurity = process.env.REACT_APP_HIDE_SECURITY !== undefined && process.env.REACT_APP_HIDE_SECURITY === 'true';
//const hideLogout = process.env.REACT_APP_HIDE_LOGOUT !== undefined && process.env.REACT_APP_HIDE_LOGOUT === 'true';
const showLogOutput = debugMode; // || !isProduction();

const darkMode = false;
let theme;
switch (mobiscroll.platform.name) {
    case 'android':
        theme = darkMode ? 'material-dark' : 'material';
        break;
    case 'windows':
    case 'wp':
        theme = darkMode ? 'windows-dark' : 'windows';
        break;
    case 'ios':
    default:
        theme = darkMode ? 'ios-dark' : 'ios';
}
//logger('theme: '+ theme);
mobiscroll.settings = {
    theme: theme
}

// config to use react router
//mobiscroll.Route = Route;
// setup the React Router with Mobiscroll
mobiscroll.setupReactRouter(Route, withRouter);

// cordova needs to use HashRouter, web to use BrowserRouter?

class App extends Component {

    constructor(props) {

        super(props);

        // redux check
        //logger('clear cc');
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            card_number: "",
            card_csc: ""
        });

        this.state = {
            cookiesEnabled: mpoSession.testCookies(),
            hasError: false,
            eventId: null
        }

    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        logger('caught');
        //console.log(error)
        //console.log(errorInfo)
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId});
        });
    }

    componentDidMount = () => {

        if (debugMode) {
            logger('App componentDidMount');
        }
        mpoSentry.addBreadcrumb('nav','App load',Sentry.Severity.Info);

        if (this.props.isAuthed) {
            Sentry.configureScope((scope) => {
                scope.setUser({
                    "id": this.props.user.customer.id,
                    "email": this.props.user.customer.email
                });
            });
        }

        if (isCordova()) {
            //setTimeout(mpoOneSignal.Init, 10000, process.env.REACT_APP_ONE_SIGNAL_APP_ID, isCustomApp);
            mpoOneSignal.Init(process.env.REACT_APP_ONE_SIGNAL_APP_ID, isCustomApp, this.props);
            mpoFirebase.SetProps(this.props);
        }

        this.handleUrlParamsLogin();

    }

    componentDidUpdate = (prevProps) => {
        if (debugMode) {
            logger('App componentDidUpdate');
        }
        //logger(prevProps);
        //logger(this.props);
        if (prevProps.location.pathname !== this.props.location.pathname || prevProps.location.search !== this.props.location.search) {
            //logger(prevProps.location.pathname+' -> '+this.props.location.pathname);
            //logger(prevProps.location.search+' -> '+this.props.location.search);
            this.handleUrlParamsLogin();
        }

        if (isCordova() /*&& (mpoFirebase._app_props === null || !mpoFirebase._initted)*/) {
            // ensure props always up-to-date
            mpoFirebase.SetProps(this.props);
        }
    }

    handleUrlParamsLogin = () => {

        if (!isCordova()) {

            if (debugMode) {
                logger('App handleUrlParamsLogin()');
                logger(this.props.location.search);
                //mobiscroll.toast({message: "A HANDLER<br/>" + this.props.location.search, color: 'info'});
            }

            const params = queryString.parse(this.props.location.search);
            const sessionId = params.sid ? params.sid : null;
            const userId = params.userid ? params.userid : null;
            const userToken = params.usertoken ? params.usertoken : null;
            const menuSlug = params.menu ? params.menu : null;
            //const otherParamAction = params.act ? params.act : null;

            if (sessionId || (userId && userToken)) {

                // regenerate search params without user/session data
                params.sid = undefined;
                params.userid = undefined;
                params.usertoken = undefined;
                let redirSearch = queryString.stringify(params);
                if (redirSearch === '') {
                    redirSearch = null;
                } else {
                    redirSearch = '?' + redirSearch;
                }

                const redirPath = menuSlug ? '/menu/' + menuSlug : this.props.location.pathname;
                if (debugMode) {
                    logger('A_URL_HANDLE ' + redirPath + '|'+redirSearch);
                    mobiscroll.toast({message: "A_URL_HANDLE<br/>"+redirPath+"<br/>"+redirSearch, color: 'info'});
                }

                //logger('UrlParamsLogin Attempt');
                mpoThirdPartyUser.login(this.props, sessionId, userId, userToken)
                    .then((res) => {
                        if (typeof res !== 'object') {
                            // error or session id login
                            // todo: to support session id login will need to call customer account action before setting customer props
                            logger(res);
                            mobiscroll.toast({message: 'Login result: '+res, color: 'danger'});
                            res = {};
                        } else if (debugMode) {
                            logger('A_LOGIN');
                            logger(res);
                            mobiscroll.toast({message: "A_LOGIN OK", color: 'info'});
                        }
                        this.props.updateStateWithCustomer(res, this.props, redirPath, redirSearch);
                    }, (err) => {
                        logger(err);
                        mobiscroll.toast({message: 'Error logging in: '+err, color: 'danger'});
                    });

            }

        }

    }

    render = () => {
        //logger('App.js render');
        //logger('App isAuthed: ' + this.props.isAuthed);

        const headerLogo = process.env.REACT_APP_HEADER_LOGO !== null && process.env.REACT_APP_HEADER_LOGO !== "" ? process.env.REACT_APP_HEADER_LOGO : null;
        const displayHeaderLogoOnLocations = process.env.REACT_APP_DISPLAY_HEADER_LOGO_ON_LOCATIONS === 'true';
        const useSimpleMerchantCard = isCustomApp && process.env.REACT_APP_USE_SIMPLE_MERCHANT_CARD === 'true';
        let headerStyle = headerLogo !== null ? {backgroundImage: `url("${headerLogo}")`} : null;

        const windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        const backgroundImage = (windowWidth > 720 || (useSimpleMerchantCard && this.props.location.pathname === '/')) && process.env.REACT_APP_BACKGROUND_IMAGE !== null && process.env.REACT_APP_BACKGROUND_IMAGE !== "" && !this.state.hasError ? process.env.REACT_APP_BACKGROUND_IMAGE : null;
        const backgroundImageClass = backgroundImage !== null ? "background-image" : null;
        const backgroundImageStyle = backgroundImage !== null ? {backgroundImage: `url("${backgroundImage}")`} : null;

        /*
        if (this.state.hasError) {
            // render fallback UI
            return (
                <div className="app-header" style={headerStyle}>
                <h1>Something went wrong.</h1>
                <p>Please report the problem then {isCordova() ? "restart the app" : "reload your browser"}. If you are logged in, you should also logout to help clear the issue.</p>
                {isCordova() ?
                    <p>If restarting the app and logging out does not clear the problem, try deleting the app and reinstalling it.</p>
                    :
                    <p>If reloading/refreshing your browser and logging out does not clear the problem, try clearing your browser cache.</p>
                }
                { this.props.hasOwnProperty('history') ? <button onClick={() => this.props.history.push('/logout') }>Logout / Clear Session</button> : null }
                <p>Please contact us if you need further assistance:</p>
                <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</button>
                </div>
            );
        }
        */

        if (!this.state.hasError && !displayHeaderLogoOnLocations && this.props.location.pathname === '/') {
            //console.log(this.props.location.pathname);
            headerStyle = null;
        }

        const appDownloadInfo = mobiscroll.platform.name === 'ios' || mobiscroll.platform.name === 'android' ? mpoAppStore.getAppDownloadInfo() : null;

        return (
            <HashRouter
                /*hashType="hashbang"*/
            >
                <mobiscroll.Page className="app-page">
                    <div className={backgroundImageClass} style={backgroundImageStyle}>
                    <Online polling={{url: pingUrl, interval: 15000, timeout: 10000}}>
                        {this.state.hasError ?
                        <div className="app-tab">
                            <h2>Something went wrong</h2>
                            <p>Please report the problem then {isCordova() ? "restart the app" : "reload your browser"}. If you are logged in, you should also logout to help clear the issue.</p>
                            {isCordova() ?
                                <p>If restarting the app and logging out does not clear the problem, try deleting the app and reinstalling it.</p>
                                :
                                <p>If reloading/refreshing your browser and logging out does not clear the problem, try clearing your browser cache.</p>
                            }
                            { this.props.hasOwnProperty('history') ? <button onClick={() => this.props.history.push('/logout') }>Logout / Clear Session</button> : null }
                            <p>Please contact us if you need further assistance:</p>
                            <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report Feedback</button>
                        </div>
                        :
                        !this.state.cookiesEnabled && !isCordova() ?
                        <div className="app-tab">
                            <h3>Online Ordering</h3>
                            <p>Browser cookies need to be enabled to use online ordering. Enable or unblock browser cookies for this site and then reload the page.</p>
                            {isIframe() && mobiscroll.platform.name === 'ios' ? <p>iOS users also need to enable cross-website tracking in iOS Settings > (your browser). Chrome users enable the "Allow Cross-Website Tracking" setting. Safari users disable "Prevent Cross-Site Tracking" setting.</p> : null}
                            {isIframe() ?
                                <React.Fragment>
                                    <p>Alternatively, open this frame in a new browser Window{appDownloadInfo !== null && appDownloadInfo.downloadAppUrl !== '' ? ', or download our app.' : null }</p>
                                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); openWindow(window.location.href, '_system', ''); }}>Open in new Window</mobiscroll.Button>
                                    {appDownloadInfo !== null && appDownloadInfo.downloadAppUrl !== '' ?
                                        <mobiscroll.Button block={true} data-icon={appDownloadInfo.downloadAppIcon} onClick={(e) => { e.preventDefault(); mpoAppStore.downloadApp(appDownloadInfo.downloadAppUrl); }}>{appDownloadInfo.downloadAppText}</mobiscroll.Button>
                                        : null}
                                </React.Fragment>
                                : null}
                        </div>
                        :
                        <Switch>
                            <Route path="/" exact={true} render={() => {
                                //logger(this.props.location);
                                /*
                                if (debugMode) {
                                    logger('A_DEFAULT_HANDLE');
                                    logger(this.props.location.search);
                                    mobiscroll.toast({
                                        message: "A_DEFAULT_HANDLE<br/>" + this.props.location.search,
                                        color: 'info'
                                    });
                                }
                                */
                                const params = queryString.parse(this.props.location.search);
                                //const menuName = isSingleStore ? singleStoreMenuSlug : (!isCordova() && params.menu ? params.menu : null);
                                const menuName = isSingleStore ? singleStoreMenuSlug : (params.menu && this.props.location.pathname !== "/menu/"+params.menu ? params.menu : null);
                                if (menuName) {
                                    if (debugMode) {
                                        logger('A_REDIR ' + menuName);
                                        logger(this.props.location.search);
                                        mobiscroll.toast({message: 'A_REDIR ' + menuName, color: 'info'});
                                    }
                                    return <Redirect to={{ pathname: "/menu/"+menuName, search: this.props.location.search}} />
                                }
                                return <Locations />
                            } } />
                            <Route path="/locations/login" exact={true} render={() => ( this.props.isAuthed ? <Locations /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname } } } /> ) } />
                            <Route path="/locations/:slug" render={() => ( isSingleStore ? <Redirect to={{ pathname: "/menu/"+singleStoreMenuSlug}} /> : <Locations /> ) } />
                            <Route path="/locations" render={() => ( isSingleStore ? <Redirect to={{ pathname: "/menu/"+singleStoreMenuSlug}} /> : <Locations /> ) } />
                            <Route path="/menu/login" exact={true} render={() => ( this.props.isAuthed ? <Menu /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname } } } /> ) } />
                            <Route path="/menu/:slug/item/:menuitemlink" component={Menu} />
                            <Route path="/menu/:slug" component={Menu} />
                            <Route path="/menu" exact={true} component={Menu} />
                            <Route path="/cart/login" render={() => ( this.props.isAuthed ? <Cart /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/cart' } } } /> ) } />
                            <Route path="/cart" exact={true} component={Cart} />
                            <Route path="/login" component={AccountLogin} />
                            <Route path="/signup" component={AccountLogin} />
                            <Route path="/signin" component={AccountLogin} />
                            <Route path="/forgot" component={AccountForgot} />
                            <Route path="/account" exact={true} render={() => ( this.props.isAuthed ? <Account /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account' } } } /> ) } />
                            <Route path="/account/orders/:slug" render={() => ( this.props.isAuthed || this.props.isAuthedGuest ? <AccountOrders /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: this.props.location.pathname } } } /> ) } />
                            <Route path="/account/orders" exact={true} render={() => ( this.props.isAuthed ? <AccountOrders /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/orders' } } } /> ) } />
                            <Route path="/account/rewards" render={() => ( this.props.isAuthed ? <AccountRewards /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/rewards' } } } /> ) } />
                            <Route path="/account/notifications" render={() => ( this.props.isAuthed ? <AccountNotifications /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/notifications' } } } /> ) } />
                            <Route path="/account/topup" render={() => ( this.props.isAuthed ? <AccountTopup /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/topup' } } } /> ) } />
                            <Route path="/account/addcard" render={() => ( this.props.isAuthed ? <AccountAddCard /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/addcard' } } } /> ) } />
                            <Route path="/account/standingorder/:slug" render={() => ( this.props.isAuthed ? <AccountStandingOrder /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: this.props.location.pathname } } } /> ) } />
                            <Route path="/account/standingorder" exact={true} render={() => ( this.props.isAuthed ? <AccountStandingOrder /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/standingorder' } } } /> ) } />
                            <Route path="/account/standingorders" exact={true} render={() => ( this.props.isAuthed ? <AccountOrders /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/standingorders' } } } /> ) } />
                            <Route path="/account/managecard" render={() => ( this.props.isAuthed ? <AccountManageCard /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/managecard' } } } /> ) } />
                            {/*<Route path="/account/billing" render={() => ( this.props.isAuthed ? <AccountBilling /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/billing' } } } /> ) } />*/}
                            <Redirect from="/account/billing" to="/account/managecard" />
                            <Route path="/account/deliveries" render={() => ( this.props.isAuthed ? <AccountDeliveries /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/deliveries' } } } /> ) } />
                            <Route path="/account/invoices" render={() => ( this.props.isAuthed ? <AccountInvoices /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/invoices' } } } /> ) } />
                            <Route path="/account/withdraw" render={() => ( this.props.isAuthed ? <AccountWithdraw /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/withdraw' } } } /> ) } />
                            <Route path="/account/password" render={() => ( this.props.isAuthed ? <AccountChangePassword /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/password' } } } /> ) } />
                            <Route path="/account/pin" render={() => ( this.props.isAuthed ? <AccountChangePin /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/pin' } } } /> ) } />
                            <Route path="/account/settings" render={() => ( this.props.isAuthed ? <AccountSettings /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/settings' } } } /> ) } />
                            <Route path="/account/feedback" exact={true} render={() => ( this.props.isAuthed ? <AccountFeedback /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/account/feedback' } } } /> ) } />
                            <Route path="/account/reset" render={() => ( this.props.isAuthed ? <AccountChangePassword /> : <AccountResetPassword /> ) } />
                            <Route path="/checkout/login" render={() => ( this.props.isAuthed ? <Checkout /> : <Redirect to={{ pathname: "/login", state: { from: this.props.location.pathname, next: '/checkout' } } } /> ) } />
                            <Route path="/checkout" exact={true} component={Checkout} />
                            <Route path="/logout" component={AccountLogout} />
                            <Route path="/logs" exact={true} component={Logs} />
                            <Route path="/tests" exact={true} component={Tests} />
                            <Route path="/about" component={About} />
                            <Route path="/terms" component={Terms} />
                            <Route path="/privacy" component={Privacy} />
                            <Route path="*" component={Locations} />
                        </Switch>
                        }
                    </Online>

                    <Offline polling={{url: pingUrl, interval: 15000, timeout: 10000}}>
                        <div className="app-tab">
                        <PullToRefresh
                            pullDownContent={<PullDownContent />}
                            releaseContent={<ReleaseContent />}
                            refreshContent={<RefreshContent />}
                            pullDownThreshold={100}
                            onRefresh={() => new Promise((resolve) => {
                                window.location.reload();
                                resolve();
                            })}
                            triggerHeight={50}
                            startInvisible={true}>
                            {this.state.hasError ?
                            <div>
                                <h2>Something went wrong</h2>
                                <p>Please report the problem then {isCordova() ? "restart the app" : "reload your browser"}. If you are logged in, you should also logout to help clear the issue.</p>
                                {isCordova() ?
                                    <p>If restarting the app and logging out does not clear the problem, try deleting the app and reinstalling it.</p>
                                    :
                                    <p>If reloading/refreshing your browser and logging out does not clear the problem, try clearing your browser cache.</p>
                                }
                                { this.props.hasOwnProperty('history') ? <button onClick={() => this.props.history.push('/logout') }>Logout / Clear Session</button> : null }
                                <p>Please contact us if you need further assistance:</p>
                                <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</button>
                            </div> : null }
                            <mobiscroll.Note color="danger">An internet connection is required</mobiscroll.Note>
                        </PullToRefresh>
                        </div>
                    </Offline>

                    <div className="app-header" style={headerStyle}>
                        <mobiscroll.Button className="mbsc-pull-right mpo-checkout-head" data-icon="cart" color="secondary" flat={true} onClick={(e) => { e.preventDefault(); this.props.location.pathname === '/cart' ? this.props.history.replace('/cart') : this.props.history.push('/cart'); }}>{this.props.order !== undefined && this.props.order.hasOwnProperty('num_items') && this.props.order.num_items ? <span className="mbsc-ms-badge mpo-checkout-head-badge">{this.props.order.num_items.toString()}</span> : null}</mobiscroll.Button>
                        <div className="app-header-logo mbsc-align-center"></div>
                        <div className="md-hamb mbsc-pull-left">
                            <mobiscroll.HamburgerNav type="hamburger" className="mpo-hamburger-head">
                                {!hideHome ? <mobiscroll.NavItem to="/" exact={true} icon="empty icon fas fa-home">Home</mobiscroll.NavItem> : null }
                                {!hideAccount ? <mobiscroll.NavItem to="/account" exact={true} icon="empty icon fas fa-address-card">Account</mobiscroll.NavItem> : null }
                                <mobiscroll.NavItem to="/cart" icon="cart">Cart</mobiscroll.NavItem>
                                {!hideAbout ? <mobiscroll.NavItem to="/about" exact={true} icon="empty icon fas fa-info-circle">About</mobiscroll.NavItem> : null }
                                {/* -- this.props.isAuthed kills app on logout
                                {this.props.isAuthed && !hideLogout ?
                                <mobiscroll.NavItem to="/logout" exact={true} icon="empty icon fas fa-sign-out-alt">Logout</mobiscroll.NavItem>
                                : null}
                                */}
                                {debugMode ? <mobiscroll.NavItem to="/tests" exact={true} icon="empty icon fas fa-vial">Tests</mobiscroll.NavItem> : null }
                                {showLogOutput ? <mobiscroll.NavItem to="/logs" exact={true} icon="empty icon fas fa-bug">Logs</mobiscroll.NavItem> : null }
                            </mobiscroll.HamburgerNav>
                        </div>
                    </div>

                    {/*
                    <mobiscroll.BottomNav>
                        {!hideHome ? <mobiscroll.NavItem to="/" exact={true} icon="home">Home</mobiscroll.NavItem> : null }
                        {!hideAccount ? <mobiscroll.NavItem to="/account" icon="user4">Account</mobiscroll.NavItem> : null }
                        <mobiscroll.NavItem to="/cart" icon="cart" badge={this.props.order !== undefined && this.props.order.hasOwnProperty('num_items') && this.props.order.num_items ? this.props.order.num_items.toString() : "0"}>Cart</mobiscroll.NavItem>
                    </mobiscroll.BottomNav>
                    */}
                    </div>
                </mobiscroll.Page>
            </HashRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        order: state.menu.order,
        user: state.user,
        isAuthed: state.user.auth.isLoggedIn && state.user.auth.isMember,
        isAuthedGuest: state.user.auth.isLoggedIn && state.user.auth.isGuest
    }
};
  
const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCart: (cart) => {
            dispatch(actions.setCartAction(cart));
            dispatch(actions.setOrderAction(cart.order));
        },
        updateStateWithCheckout: (checkout) => dispatch(actions.setCheckoutAction(checkout)),
        updateStateWithCustomer: (customer, ownProps, redirPath = null, redirSearch = null) => {
            dispatch(actions.setCustomerAction(customer, ownProps, redirPath, redirSearch));
        },
    }
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );
