import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import AccountBottomNav from '../../components/account/BottomNav';
import { validateAccountPin } from '../../shared/validate';
import {Helmet} from "react-helmet";

class AccountChangePin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            formErrors: {},
            formValues: {
                current_pin: '',
                new_pin: '',
                confirm_new_pin: '',
            }
        }
    }

    UNSAFE_componentWillMount() {
        this.initValidation();
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','ChangePin',Sentry.Severity.Info);
        this.getAccount();
    }

    // set up validation
    initValidation = () => {
        this.validationSettings = {
            current_pin: [],
            new_pin: [this.requiredCheck],
            confirm_new_pin: [this.requiredCheck],
        }
    }
    
    // validation
    validateField = (key, value) => {
        let invalid = false;
        if (this.validationSettings[key] !== undefined) {
            for(let i = 0; i < this.validationSettings[key].length; i++) { // interate on validators
                const validator = this.validationSettings[key][i];
                invalid = invalid || validator.call(this, value);
            }
        }
        let errorUpd = {};
        errorUpd[key] = invalid;
        return errorUpd;
    }
    
    // validation check functions
    requiredCheck = (value) => {
        if (!!value) {
            return false;
        } else {
            return 'This field is required';
        }
    }
    
    validateAllFields = () => {
        const errorsUpd = Object.assign({}, this.state.formErrors); 
        const values = Object.assign({}, this.state.formValues); 
        let allValid = true;

        for (let key in values) {
            errorsUpd[key] = this.validateField(key, values[key])[key];
            allValid = allValid && !errorsUpd[key];
        }
        
        this.setState({
            formErrors: errorsUpd
        });

        if (allValid) {
            // send to server
            this.updatePin();
        }        
    }

    getAccount = () => {

        const data = {
            RequestAction: 'CustomerAccount'
        };

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                //mpoSentry.captureMessage('Session expired', Sentry.Severity.Warning);
            } else if (response.data.ResponseCode === "SUCCESS") {
                if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                    this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                    mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                    //mpoSentry.captureMessage('Session expired', Sentry.Severity.Warning);
                } else {
                    this.setState({
                        isLoading: false,
                        modules: {
                            standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                            rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                            topup: response.data.Response.account.is_topup_enabled === 1,
                            facebook: response.data.Response.customer.is_facebook_connected,
                            apple: response.data.Response.customer.is_apple_connected,
                            google: response.data.Response.customer.is_google_connected
                        }
                    });
                }
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    updatePin = () => {

        const data = {
            RequestAction: 'ChangePin',
            old_pin: this.state.formValues.current_pin,
            new_pin: this.state.formValues.new_pin,
            confirm_pin: this.state.formValues.confirm_new_pin,
        };
        //logger(data);
        mpoSentry.addBreadcrumb('action',data.RequestAction,Sentry.Severity.Info);

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response, this.props);
            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                //mpoSentry.captureMessage('Session expired', Sentry.Severity.Warning);
            } else if (response.data.ResponseCode === "SUCCESS") {
                this.props.updateStateWithCustomer(response.data.Response.customer, null);
                mobiscroll.toast({message: 'PIN updated', color: 'success'});
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    onCurrentPinChange = (e, inst) => {
        this.setStateWithPin("current_pin", e.valueText);
    }

    onNewPinChange = (e, inst) => {
        this.setStateWithPin("new_pin", e.valueText);
    }

    onConfirmPinChange = (e, inst) => {
        this.setStateWithPin("confirm_new_pin", e.valueText);
    }

    setStateWithPin(key, value) {
        const valueUpd = Object.assign({}, this.state.formValues); 
        valueUpd[key] = value;
        const errorUpd = this.validateField(key, value);
        
        this.setState({
            formValues: valueUpd,
            formErrors: errorUpd
        });
    }

    render = () => {

        if (this.state.isLoading) {
            return (
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Loading...</div>
                        </div>
                    </div>
                </mobiscroll.Form>
            );
        }

        if (!this.props.user.customer.has_mobile) {
            // todo: include mobile on form instead
            return (
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <Helmet>
                            <title>{process.env.REACT_APP_APP_TITLE} Account</title>
                        </Helmet>
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">My PIN</div>

                            <div className="mbsc-padding">
                                A mobile phone number is required to set up your PIN. Add your mobile phone number in My Account.
                            </div>

                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/account'); }}>My Account</mobiscroll.Button>
                            </div>
                        </div>
                    </div>
                </mobiscroll.Form>
            );
        }

        return (
            <React.Fragment>
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <Helmet>
                            <title>{process.env.REACT_APP_APP_TITLE} Account</title>
                        </Helmet>
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">My PIN</div>

                            {this.props.user.customer.has_mobile_pin ?
                            <mobiscroll.Numpad
                                fill="ltr"
                                template="dddddd"
                                allowLeadingZero={true}
                                placeholder=""
                                showOnFocus={true}
                                validate={validateAccountPin}
                                onSet={this.onCurrentPinChange}>
                                <mobiscroll.Input 
                                    labelStyle="stacked" 
                                    value={this.state.formValues.current_pin} 
                                    valid={!this.state.formErrors.current_pin}
                                    //errorMessage={this.state.formErrors.current_pin}
                                    passwordToggle={true} 
                                    data-fieldname="current_pin">Current PIN</mobiscroll.Input>
                            </mobiscroll.Numpad>
                            : null }

                            <mobiscroll.Numpad
                                fill="ltr"
                                template="dddddd"
                                allowLeadingZero={true}
                                placeholder=""
                                showOnFocus={true}
                                validate={validateAccountPin}
                                onSet={this.onNewPinChange}>
                                <mobiscroll.Input 
                                    labelStyle="stacked" 
                                    value={this.state.formValues.new_pin} 
                                    valid={!this.state.formErrors.new_pin}
                                    //errorMessage={this.state.formErrors.new_pin}
                                    passwordToggle={true} 
                                    data-fieldname="new_pin">New PIN</mobiscroll.Input>
                            </mobiscroll.Numpad>

                            <mobiscroll.Numpad
                                fill="ltr"
                                template="dddddd"
                                allowLeadingZero={true}
                                placeholder=""
                                showOnFocus={true}
                                validate={validateAccountPin}
                                onSet={this.onConfirmPinChange}>
                                <mobiscroll.Input 
                                    labelStyle="stacked" 
                                    value={this.state.formValues.confirm_new_pin} 
                                    valid={!this.state.formErrors.confirm_new_pin}
                                    //errorMessage={this.state.formErrors.confirm_new_pin}
                                    passwordToggle={true} 
                                    data-fieldname="confirm_new_pin">Confirm New PIN</mobiscroll.Input>
                            </mobiscroll.Numpad>
                        </div>

                        <div className="mbsc-padding">
                            <mobiscroll.Button block={true} onClick={this.validateAllFields}>Save</mobiscroll.Button>
                        </div>
                    </div>
                </mobiscroll.Form>

                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = null; //'/account/pin';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountChangePin));
