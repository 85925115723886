import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import { mpoAccount } from '../../lib/Account';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import AccountBottomNav from '../../components/account/BottomNav';
import {Helmet} from "react-helmet";

class AccountBilling extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            topup: {
                currency_sign: '', 
                balance: 0.00,
                balance_held: 0.00,
                balance_debit: 0.00,
                balance_transferable: 0.00,
                has_saved_card: false,
                auto_topup_amount: 0.00
            }
        }

    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Billing',Sentry.Severity.Info);
        mpoAccount.getAccount(this.onGetAccount, 'Billing');
    }

    onGetAccount = (response) => {

        if (response.data.ResponseCode === "AUTH") {
            this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
        } else if (response.data.ResponseCode === "SUCCESS") {
            if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-2)', color: 'danger'});
                //mpoSentry.captureMessage('Session expired (MSG FE-AUTH-2)', Sentry.Severity.Warning);
            } else {
                this.setState({
                    isLoading: false,
                    modules: {
                        standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                        rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                        topup: response.data.Response.account.is_topup_enabled === 1,
                        facebook: response.data.Response.customer.is_facebook_connected,
                        apple: response.data.Response.customer.is_apple_connected,
                        google: response.data.Response.customer.is_google_connected
                    },
                    topup: {
                        currency_sign: response.data.Response.account.currency_sign, 
                        balance: parseFloat(response.data.Response.customer.balance),
                        balance_held: parseFloat(response.data.Response.customer.balance_held),
                        balance_debit: parseFloat(response.data.Response.customer.balance_debit),
                        balance_transferable: parseFloat(response.data.Response.customer.balance_transferable),
                        has_saved_card: response.data.Response.customer.has_saved_card,
                        auto_topup_amount: parseFloat(response.data.Response.customer.auto_topup_amount)
                    },
                });
            }
        } else {
            //response.data.ResponseCode === "ERROR"
        }
    }

    render = () => {

        if (this.state.isLoading) {
            return (
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                    <div className="mbsc-form-group">
                        <div className="mbsc-form-group-title">Loading...</div>
                        </div>
                    </div>
                </mobiscroll.Form>
            );
        }

        return (
            <React.Fragment>
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <Helmet>
                            <title>{process.env.REACT_APP_APP_TITLE} Billing</title>
                        </Helmet>

                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Billing Options</div>

                            {this.state.modules.topup ?
                            <div className="mbsc-padding">
                                <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/topup') }}>Topup Now</mobiscroll.Button>
                            </div>
                            : null}

                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/managecard') }}>Manage Card</mobiscroll.Button>
                            </div>

                        </div>

                        {this.state.modules.topup || this.state.topup.balance > 0 || this.state.topup.balance_debit > 0 ?
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Account Balance</div>
                            <mobiscroll.Input 
                                disabled={true}
                                type="text" 
                                defaultValue={this.state.topup.currency_sign+parseFloat(this.state.topup.balance).toFixed(2)}>Credit</mobiscroll.Input>
                            {this.state.topup.balance_debit > 0 ? 
                            <mobiscroll.Input 
                                disabled={true}
                                type="text" 
                                defaultValue={this.state.topup.currency_sign+parseFloat(this.state.topup.balance_debit).toFixed(2)}>Debit</mobiscroll.Input>
                            : null }
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <mobiscroll.Button block={true} disabled={this.state.topup.balance === 0} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/withdraw') }}>Withdraw Funds</mobiscroll.Button>
                            </div>
                        </div>
                        : null}
                    </div>
                </mobiscroll.Form>

                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
            </React.Fragment>
        );

    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account/billing';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountBilling));
