import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import ContentLoader from "react-content-loader"

class ProductCardItem extends Component {

    onClick = (event, inst) => {
        if (this.props.onClick !== undefined && typeof(this.props.onClick) === 'function') {
            this.props.onClick(event, inst);
        }
    }

    render = () => {
        //console.log(this.props);

        if (this.props.item.hasOwnProperty('product_placeholder')) {
            return (
                    <mobiscroll.Card>
                        <mobiscroll.CardContent>
                            <ContentLoader
                                height={400}
                                width={400}
                                speed={2}
                                primaryColor="#f3f3f3"
                                secondaryColor="#a8a8a8">
                                <rect x="70" y="15" rx="4" ry="4" width="117" height="6" />
                                <rect x="70" y="35" rx="3" ry="3" width="85" height="6" />
                                <rect x="0" y="80" rx="3" ry="3" width="350" height="6" />
                                <rect x="0" y="100" rx="3" ry="3" width="380" height="6" />
                                <rect x="0" y="120" rx="3" ry="3" width="201" height="6" />
                                <circle cx="30" cy="30" r="30" />
                            </ContentLoader>
                        </mobiscroll.CardContent>
                    </mobiscroll.Card>
            )
        }

        const defaultProductSquare = process.env.REACT_APP_DEFAULT_PRODUCT_SQUARE;

        let dietary = "";
        if (this.props.item.is_gluten_free) {
            dietary += "(GF) ";
        }
        if (this.props.item.is_egg_free) {
            dietary += "(EF) ";
        }
        if (this.props.item.is_dairy_free) {
            dietary += "(DF) ";
        }
        if (this.props.item.is_lactose_free) {
            dietary += "(LF) ";
        }
        if (this.props.item.is_vegetarian) {
            dietary += "(V) ";
        }
        if (this.props.item.is_vegan) {
            dietary += "(VE) ";
        }
        if (this.props.item.is_halal) {
            dietary += "(Halal) ";
        }

        const imgUrl = this.props.item.img_url_lge || this.props.item.img_url;

        const gridClasses = this.props.hasOwnProperty('fullScreen') && this.props.fullScreen ?
            "mbsc-col-6 mbsc-col-sm-6 mbsc-col-md-4 mbsc-col-lg-3 mbsc-col-xl-3" :
            "mbsc-col-6 mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-lg-4 mbsc-col-xl-4";

        const divStyle = this.props.item.hasOwnProperty('is_visible') && this.props.item.is_visible === false ? {display: 'none'} : {};

        return (
            <div className={gridClasses} style={divStyle}>
                <mobiscroll.Card style={{marginTop: 0}}>
                    {
                    this.props.displayImages ?
                        (imgUrl ?
                        <img src={imgUrl} alt="Product" onError={(e)=>{e.target.onerror = null; e.target.src=defaultProductSquare}} />
                        :
                        <img src={defaultProductSquare} alt="Product" />
                        ) : null
                    }
                    <mobiscroll.CardHeader style={{paddingBottom: "0.5em"}}>
                        <mobiscroll.CardTitle>{this.props.item.name}</mobiscroll.CardTitle>
                    </mobiscroll.CardHeader>
                    <mobiscroll.CardContent style={{paddingRight: "0.5em"}}>
                        <span className="mbsc-desc mpo-product-desc">{this.props.item.desc}</span>
                        {dietary !== "" ? <span className="mbsc-desc mpo-product-dietary">{dietary}</span> : null}
                    </mobiscroll.CardContent>
                    <mobiscroll.CardFooter style={{paddingRight: "0.5em"}}>
                        <span className="mpo-product-price">{this.props.locale.currency_sign}{this.props.item.base_price}</span>
                        {this.props.item.is_available ?
                            <mobiscroll.Button className="mbsc-pull-right" disabled={!this.props.item.is_available} icon="plus" color="success" style={{color: '#fff', padding: "0.5em"}} data-component="ProductCardItem" data-itemid={this.props.item.id} data-catid={this.props.item.cat_id} data-mpo-menu-item-id={this.props.item.id} data-mpo-option-count={this.props.item.option_count} onClick={(e,i) => { e.preventDefault(); this.onClick(e,i); }}>Add to Cart</mobiscroll.Button>
                        :
                            <span className="mbsc-pull-right mbsc-desc mpo-product-desc">{this.props.item.available_str}</span>
                        }
                    </mobiscroll.CardFooter>
                </mobiscroll.Card>
            </div>
        );
    }
}

export default withRouter(ProductCardItem);