import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import RewardItem from '../../components/account/RewardItem';
import AccountBottomNav from '../../components/account/BottomNav';
import {Helmet} from "react-helmet";

class AccountRewards extends Component {

    constructor(props) {
        super(props);
        //console.log('Rewards props', props);

        this.state = {
            isLoading: true,
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            rewards: [],
        }
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Rewards',Sentry.Severity.Info);
        this.getAccount();
    }

    getAccount = () => {

        const data = {
            RequestAction: 'CustomerAccount'
        };

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
            } else if (response.data.ResponseCode === "SUCCESS") {
                if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                    this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                    mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                    //mpoSentry.captureMessage('Session expired', Sentry.Severity.Warning);
                } else {
                    //console.log(this.state);
                    this.setState({
                        //isLoading: false,
                        modules: {
                            standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                            rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                            topup: response.data.Response.account.is_topup_enabled === 1,
                            facebook: response.data.Response.customer.is_facebook_connected,
                            apple: response.data.Response.customer.is_apple_connected,
                            google: response.data.Response.customer.is_google_connected
                        }
                    });

                    this.loadRewards();
                }
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    loadRewards = () => {
        //console.log('loadRewards');

        this.setState({
            isLoading: this.props.user.customer.id > 0,
            rewards: [],
        });

        const data = {
            RequestAction: 'Rewards',
        };

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response, this.props);
            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
            } else if (response.data.ResponseCode === "SUCCESS") {
                //this.props.updateStateWithCustomer(response.data.Response.customer, null);
                //console.log(this.state);
                this.setState({
                    isLoading: false,
                    rewards: response.data.Response.rewards
                });
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    onItemTap = (event, inst) => {
        this.props.history.push('/menu/'+this.state.rewards[event.index].menu_name);
    }

    render = () => {
        //let isData = this.state.rewards.length > 0;
        let heading = 'My Account';

        if (this.state.isLoading) {
            heading = 'Loading...';
        } else if (this.props.user.customer.id === 0) {
            heading = 'Account required';
        } else {
            heading = 'My Rewards';
        }

        /*
        if (!isData) {
            return (
                <div className="app-tab">
                    <mobiscroll.Form className="mpo-form-width-md">
                    </mobiscroll.Form>
    
                    <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
                </div>
            );
        }
        */

        return (
            <div className="app-tab">
                <Helmet>
                    <title>{process.env.REACT_APP_APP_TITLE} Rewards</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    <div className="mbsc-form-group">
                        <div className="mbsc-form-group-title">{heading}</div>

                        <mobiscroll.Listview
                            className="mbsc-product-list"
                            itemType={RewardItem}
                            data={this.state.rewards}
                            fixedHeader={true}
                            enhance={true}
                            swipe={false}
                            striped={true}
                            onItemTap={this.onItemTap}
                        />
                    </div>
                </mobiscroll.Form>

                {!this.state.isLoading ?
                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
                : null }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account/rewards';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountRewards));
