import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import { mpoAccount } from '../../lib/Account';
import { validateMobile, validateEmail } from '../../shared/validate';
import AccountBottomNav from '../../components/account/BottomNav';
import {attachDeviceInfoToData, isCordova, openWindow} from '../../shared/utility';
import { mpoOneSignal } from '../../lib/OneSignal';
import {Helmet} from "react-helmet";

const privacyUrl = process.env.REACT_APP_APP_PRIVACY_URL !== undefined && process.env.REACT_APP_APP_PRIVACY_URL !== null && process.env.REACT_APP_APP_PRIVACY_URL !== "" ? process.env.REACT_APP_APP_PRIVACY_URL : null;

class Account extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            closeRequestSent: false,
            topup: {
                currency_sign: '',
                balance: 0.00,
                balance_held: 0.00,
                balance_debit: 0.00,
                balance_transferable: 0.00,
                has_saved_card: false,
                auto_topup_amount: 0.00
            },
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            formErrors: {},
            formValues: {
                name: this.props.user.customer.name,
                email: this.props.user.customer.email,
                mobile: this.props.user.customer.mobile,
                gender: null,
                birthday: null,
                has_birthday: false
            }
        };
    }

    UNSAFE_componentWillMount() {
        //console.log('account comp will mount');
        this.initValidation();
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Account',Sentry.Severity.Info);
        mpoAccount.getAccount(this.onGetAccount, 'Account');
    }

    onGetAccount = (response) => {

        if (response.data.ResponseCode === "AUTH") {
            this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
        } else if (response.data.ResponseCode === "SUCCESS") {
            if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-2)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-2)', Sentry.Severity.Warning);
            } else {
                this.setState({
                    isLoading: false,
                    topup: {
                        currency_sign: response.data.Response.account.currency_sign, 
                        balance: parseFloat(response.data.Response.customer.balance),
                        balance_held: parseFloat(response.data.Response.customer.balance_held),
                        balance_debit: parseFloat(response.data.Response.customer.balance_debit),
                        balance_transferable: parseFloat(response.data.Response.customer.balance_transferable),
                        has_saved_card: response.data.Response.customer.has_saved_card,
                        auto_topup_amount: parseFloat(response.data.Response.customer.auto_topup_amount)
                    },
                    modules: {
                        standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                        rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                        topup: response.data.Response.account.is_topup_enabled === 1,
                        facebook: response.data.Response.customer.is_facebook_connected,
                        apple: response.data.Response.customer.is_apple_connected,
                        google: response.data.Response.customer.is_google_connected
                    },
                    formValues: {
                        name: response.data.Response.customer.name,
                        email: response.data.Response.customer.email,
                        mobile: response.data.Response.customer.mobile,
                        gender: null,
                        birthday: response.data.Response.customer.birthday,
                        has_birthday: response.data.Response.customer.birthday !== null
                    }
                });
            }
        } else {
            //response.data.ResponseCode === "ERROR"
        }
    }

    onFormChange = (key, event) => {
        const valueUpd = Object.assign({}, this.state.formValues); 
        valueUpd[key] = event.target.value;
        const errorUpd = this.validateField(key, event.target.value);
        
        this.setState({
            formValues: valueUpd,
            formErrors: errorUpd
        });
    }

    // set up validation
    initValidation = () => {
        this.validationSettings = {
            name: [this.requiredCheck, this.minlengthCheck.bind(this, 3)],
            email: [this.requiredCheck, this.emailCheck],
            mobile: [this.requiredCheck],
            birthday: [],
            gender: []
        }
    }

    // validation
    validateField = (key, value) => {
        let invalid = false;
        if (this.validationSettings[key] !== undefined) {
            for(let i = 0; i < this.validationSettings[key].length; i++) { // interate on validators
                const validator = this.validationSettings[key][i];
                invalid = invalid || validator.call(this, value);
            }
        }
        let errorUpd = {};
        errorUpd[key] = invalid;
        return errorUpd;
    }
    
    validateAllFields = () => {
        const errorsUpd = Object.assign({}, this.state.formErrors); 
        const values = Object.assign({}, this.state.formValues); 
        let allValid = true;
        for (let key in values) {
            errorsUpd[key] = this.validateField(key, values[key])[key];
            allValid = allValid && !errorsUpd[key];
        }
        
        this.setState({
            formErrors: errorsUpd
        });
        
        if (allValid) {
            // send to server
            this.updateProfile();
        }        
    }
    
    // validation check functions
    requiredCheck = (value) => {
        if (!!value) {
            return false;
        } else {
            return 'This field is required';
        }
    }
    
    minlengthCheck = (minlength, value) => {
        if (!value || value.length < minlength) {
            return 'Has to be at least ' + minlength + ' characters long';
        }
        else {
            return false;
        }
    }
    
    emailCheck = (value) => {
        if (validateEmail(value)) {
            return false;
        } else {
            return 'Enter a valid email address';
        }
    }

    onCustomerMobileChange = (e, inst) => {
        const valueUpd = Object.assign({}, this.state.formValues); 
        const key = "mobile";
        valueUpd[key] = e.valueText;
        const errorUpd = this.validateField(key, e.valueText);
        
        this.setState({
            formValues: valueUpd,
            formErrors: errorUpd
        });
    }

    onBirthdayChange = (e, inst) => {
        //console.log('onBirthdayChange', e, inst);
        //console.log(e.valueText);

        const valueUpd = Object.assign({}, this.state.formValues);
        const key = "birthday";
        valueUpd[key] = e.valueText; //inst.getVal();
        const errorUpd = this.validateField(key, inst.getVal());

        this.setState({
            formValues: valueUpd,
            formErrors: errorUpd
        });

    }

    updateProfile = () => {

        const data = {
            RequestAction: 'ChangeProfile',
            name: this.state.formValues.name,
            email: this.state.formValues.email,
            mobile: this.state.formValues.mobile,
            birthday: this.state.formValues.birthday,
        };

        mpoSentry.addBreadcrumb('action',data.RequestAction,Sentry.Severity.Info);

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response, this.props);
            //console.log(response.data.ResponseCode);
            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                //mpoSentry.captureMessage('Session expired', Sentry.Severity.Warning);
            } else if (response.data.ResponseCode === "SUCCESS") {
                //console.log(response.data.Response);
                this.props.updateStateWithCustomer(response.data.Response, null);
                mobiscroll.toast({message: 'Account updated', color: 'success'});
                Sentry.configureScope((scope) => {
                    scope.setUser({
                        "id": response.data.Response.id,
                        "email": response.data.Response.email
                    });
                });
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error AA1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    submitAccountClosureRequest = () => {

        this.setState({
            closeRequestSent: true
        });

        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: 'Submitting request...', duration: 15000, display: 'center', color: 'info'});

        const data = {
            RequestAction: 'CloseAccount'
        };

        mpoSentry.addBreadcrumb('action',data.RequestAction,Sentry.Severity.Info);

        axiosInstance.post(null, data)
            .then(response => {
                mobiscroll.notification.dismiss();

                if (response.data.ResponseCode === "AUTH") {
                    this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                    mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-1)', color: 'danger'});
                    mpoSentry.captureMessage('Session expired (MSG FE-AUTH-1)', Sentry.Severity.Warning);
                } else if (response.data.ResponseCode === "SUCCESS") {
                    mobiscroll.toast({message: 'Your request to permanently delete this account has been received and will be processed in accordance to our privacy policy.', color: 'success'});
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                    this.setState({
                        closeRequestSent: false
                    });
                }
            })
            .catch(error => {
                mobiscroll.notification.dismiss();
                //console.log(error);
                mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                mpoSentry.captureException(error);
                this.setState({
                    closeRequestSent: false
                });
            });

    }


    render = () => {

        if (this.state.isLoading) {
            return (
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                    <div className="mbsc-form-group">
                        <div className="mbsc-form-group-title">Loading...</div>
                        </div>
                    </div>
                </mobiscroll.Form>
            );
        }

        let accountTitle = "My Account";
        if (this.props.user.auth.isLoggedIn && this.props.user.auth.isGuest) {
            accountTitle = "Guest Account";
        } else if (this.props.user.auth.isStaff) {
            accountTitle = "Staff Account";
        } else if (this.props.user.auth.isTest) {
            accountTitle = "Test Account";
        }

        return (
            <React.Fragment>
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <Helmet>
                            <title>{process.env.REACT_APP_APP_TITLE} Account</title>
                        </Helmet>
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">{accountTitle}</div>

                            {this.state.modules.apple ?
                                <mobiscroll.Note color="info">Signed in with Apple</mobiscroll.Note>
                                : null }
                            {this.state.modules.google ?
                                <mobiscroll.Note color="info">Signed in with Google</mobiscroll.Note>
                                : null }

                            <mobiscroll.Input 
                                labelStyle="stacked" 
                                placeholder="First Last"
                                value={this.state.formValues.name} 
                                onChange={this.onFormChange.bind(this, "name")} 
                                valid={!this.state.formErrors.name}
                                errorMessage={this.state.formErrors.name}
                                name="customerName" 
                                data-fieldname="name">Name</mobiscroll.Input>
                            
                            <mobiscroll.Input 
                                labelStyle="stacked" 
                                type="email" 
                                value={this.state.formValues.email} 
                                onChange={this.onFormChange.bind(this, "email")} 
                                valid={!this.state.formErrors.email}
                                errorMessage={this.state.formErrors.email}
                                name="customerEmail"
                                autoComplete="email"
                                disabled={(this.state.modules.facebook || this.state.modules.apple || this.state.modules.google) && this.state.formValues.email !== ''}
                                data-fieldname="email">Email</mobiscroll.Input>
                            
                            <mobiscroll.Numpad
                                fill="ltr"
                                template="dddd ddd ddd"
                                allowLeadingZero={true}
                                placeholder=""
                                showOnFocus={true}
                                validate={validateMobile}
                                onSet={this.onCustomerMobileChange}>
                                <mobiscroll.Input 
                                    labelStyle="stacked" 
                                    defaultValue={this.state.formValues.mobile} 
                                    valid={!this.state.formErrors.mobile}
                                    //errorMessage={this.state.formErrors.mobile}
                                    name="customerMobile" 
                                    data-fieldname="mobile">Mobile</mobiscroll.Input>
                            </mobiscroll.Numpad>

                            <label>
                                Birthday
                                <mobiscroll.Date
                                    labelStyle="stacked"
                                    value={this.state.formValues.birthday}
                                    //min={new Date()}
                                    //max={new Date(2099, 11, 1)}
                                    dateFormat="dd/mm"
                                    //returnFormat="locale"
                                    disabled={this.state.formValues.has_birthday}
                                    onSet={this.onBirthdayChange}
                                />
                            </label>

                            {/*
                            <label>
                                Birthday
                                <mobiscroll.Date 
                                    value={this.state.formValues.birthday} 
                                    min={new Date(1910, 1, 1)}
                                    max={new Date(2009, 11, 31)}
                                    dateFormat="dd/mm/yy"
                                    valid={!this.state.formErrors.birthday}
                                    errorMessage={this.state.formErrors.birthday}
                                    showOnFocus={true}
                                    onSet={this.setBirthday} />
                            </label>

                            <mobiscroll.Segmented name="gender" value="M">Male</mobiscroll.Segmented>
                            <mobiscroll.Segmented name="gender" value="F">Female</mobiscroll.Segmented>
                            */}
                        </div>

                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            <mobiscroll.Button color="success" block={true} onClick={this.validateAllFields}>Save Changes</mobiscroll.Button>
                        </div>

                        {this.state.modules.topup || this.state.topup.balance > 0 || this.state.topup.balance_debit > 0 ?
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Account Balance</div>
                            <mobiscroll.Input 
                                disabled={true}
                                type="text" 
                                defaultValue={this.state.topup.currency_sign+parseFloat(this.state.topup.balance).toFixed(2)}>Credit</mobiscroll.Input>
                            {this.state.topup.balance_debit > 0 ? 
                            <mobiscroll.Input 
                                disabled={true}
                                type="text" 
                                defaultValue={this.state.topup.currency_sign+parseFloat(this.state.topup.balance_debit).toFixed(2)}>Debit</mobiscroll.Input>
                            : null }
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/billing'); }}>Manage Funds</mobiscroll.Button>
                            </div>
                        </div>
                        : null}

                        {isCordova() && !mpoOneSignal.IsRegistered() && mobiscroll.platform.name === 'ios' ?
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Push Notifications</div>
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <p>Receive app notifications about your orders and occasional discounts and offers.</p>
                                <mobiscroll.Button color="info" block={true} onClick={(e) => { e.preventDefault(); mpoOneSignal.DisplayRegisterPopup(); }}>Get Notified</mobiscroll.Button>
                            </div>
                        </div>
                        : null}

                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Delete Account</div>
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <React.Fragment>
                                    {privacyUrl === null ?
                                        <p>
                                            Your information is protected in accordance to our <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push('/privacy');
                                        }}>privacy policy</a>. You can request that your data be permanently deleted and your account will be closed. This action cannot be reversed.</p>
                                        :
                                        (isCordova() ?
                                                <p>
                                                    Your information is protected in accordance to our <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    openWindow(privacyUrl, '_system', '');
                                                }}>privacy policy</a>. You can request that your data be permanently deleted and your account will be closed. This action cannot be reversed.</p>
                                                :
                                                <p>
                                                    Your information is protected in accordance to our <a href={privacyUrl} target="_blank">privacy policy</a>. You can request that your data be permanently deleted and your account will be closed. This action cannot be reversed.
                                                </p>
                                        )
                                    }

                                </React.Fragment>
                                <mobiscroll.Button color="danger" block={true} disabled={this.state.closeRequestSent} onClick={(e) => {
                                    e.preventDefault();
                                    mobiscroll.confirm({
                                        title: 'Delete Account',
                                        message: 'Permanently delete this account in accordance with our privacy policy?',
                                        okText: 'Delete Forever',
                                        cancelText: 'Do Not Delete'
                                    }).then((res) => {
                                        if (res) {
                                            // create support ticket
                                            this.submitAccountClosureRequest();
                                        }
                                    });
                                }}>Request Account Deletion</mobiscroll.Button>
                            </div>
                        </div>

                    </div>
                </mobiscroll.Form>

                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));
