import * as actionTypes from './actionTypes';

export const saveCustomerAction = ( customer ) => {
    return {
        type: actionTypes.SET_CUSTOMER,
        payload: customer
    };
}

export const setCustomerAction = ( customer, ownProps = null, redirPath = null, redirSearch = null ) => {
    //console.log('setCustomerAction');
    return dispatch => {
        let auth = {
            isLoggedIn: customer.id > 0,
            isMember: parseInt(customer.status,10) === 1 || parseInt(customer.status,10) === 8 || parseInt(customer.status,10) === 9,
            isGuest: parseInt(customer.id,10) === 0 || parseInt(customer.status,10) === 2,
            isStaff: parseInt(customer.status,10) === 8,
            isTest: parseInt(customer.status,10) === 9,
        }
        if (customer.id > 0 && customer.hasOwnProperty('login')) {
            auth.login = {
                method: customer.login.method,
                value: customer.login.value,
                token: customer.login.token
            }
        }
        dispatch(setAuthAction(auth));

        if (customer.hasOwnProperty('login')) {
            delete customer.login;
        }
        dispatch(saveCustomerAction(customer));

        dispatch(setGuestAction({}));
        dispatch(setCheckoutAction({}));

        if (ownProps !== undefined && ownProps !== null && ownProps.hasOwnProperty('history') && redirPath !== null) {
            setTimeout(() => {
                //console.log('ownProps', ownProps);
                //console.log('redirect now', redirPath)
                //ownProps.history.push(redirPath);
                const location = {
                    pathname: redirPath,
                    state: {fromSetCustomerAction: true}
                }
                if (redirSearch !== null) {
                    location.search = redirSearch;
                }
                if (ownProps.location.pathname === redirPath) {
                    //console.log('setCustomerAction replace '+ownProps.location.pathname + ' -> ' +redirPath+' '+redirSearch);
                    ownProps.history.replace(location);
                } else {
                    //console.log('setCustomerAction push '+ownProps.location.pathname + ' -> ' +redirPath+' '+redirSearch);
                    ownProps.history.push(location);
                }
            }, 2000);
        }
    }
};

export const setGuestAction = ( guest ) => {
    return {
        type: actionTypes.SET_GUEST,
        payload: guest
    };
};

export const setAuthAction = ( auth ) => {
    return {
        type: actionTypes.SET_AUTH,
        payload: auth
    };
};

export const setCheckoutAction = ( checkout ) => {
    return {
        type: actionTypes.SET_CHECKOUT,
        payload: checkout
    };
};
