export const SET_LOCATION_GROUP = 'SET_LOCATION_GROUP';
export const SET_MERCHANTS = 'SET_MERCHANTS';
export const SET_MERCHANTS_NEARBY = 'SET_MERCHANTS_NEARBY';
export const SET_MERCHANTS_SEARCH = 'SET_MERCHANTS_SEARCH';
export const SET_MERCHANTS_FAVE = 'SET_MERCHANTS_FAVE';
export const SET_MERCHANTS_HISTORY = 'SET_MERCHANTS_HISTORY';
export const SET_MERCHANT_TYPES = 'SET_MERCHANT_TYPES';
export const SET_MERCHANT_GROUPS = 'SET_MERCHANT_GROUPS';
export const SET_LOCATION_SETTINGS = 'SET_LOCATION_SETTINGS';
export const SET_SLUG = 'SET_SLUG';
export const SET_MERCHANT = 'SET_MERCHANT';
export const SET_MENU_ITEMS = 'SET_MENU_ITEMS';
export const SET_FULFILMENT_TYPE = 'SET_FULFILMENT_TYPE';
export const SET_FULFILMENT_OPTIONS = 'SET_FULFILMENT_OPTIONS';
export const SET_ORDER = 'SET_ORDER';
export const SET_CART = 'SET_CART';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_GUEST = 'SET_GUEST';
export const SET_AUTH = 'SET_AUTH';
export const SET_CHECKOUT = 'SET_CHECKOUT';
